import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TRANSACTIONS_PARTNER_FEE_MODULE } from "../../../utils/constants/actionTypes";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { transactionsPartnerFeeApi } from "../../../services/transactionsPartnerFeeApi";
import { toIDR } from "../../../utils/helpers/currency";
import { DATE_PICKER, PARTNER_SELECT } from "../../../utils/constants/inputTypes";

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    totalAmount,
    filter,
  } = useSelector(state => state.transactions.partnerFee);

  const getTransactionsPartnerFeeList = useMemo(
    () => AwesomeDebouncePromise(transactionsPartnerFeeApi.list, 500),
    [],
  )

  const columns = [
    {
      title: 'Trans. Date', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    },
    { title: 'Partner', key: 'sender', name: 'sender', render: v => `${v.name} | ${v.uniqueId}` },
    { title: 'Trans. Code', key: 'code', name: 'code' },
    { title: 'Primary', key: 'amount.primary', name: 'amountPrimary', render: v => toIDR(v) }
  ];

  const excelColumns = useMemo(() => [
    { title: 'Trans. Date', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm') },
    { title: 'Partner', key: 'sender', render: v => `${v.name} | ${v.uniqueId}` },
    { title: 'Trans. Code', key: 'code' },
    { title: 'Primary', key: 'amount.primary' },
  ], [])

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const partner = filter.partner && typeof filter.partner === 'object' ? filter.partner.value : false;

    return {
      page: currentPage,
      limit,
      sort,
      partner,
      startDate,
      endDate
    }
  }, [filter, currentPage, limit, sort])

  return (
    <div>
      <Resource
        title="Transactions Partner Fee"
        subTitle="Full Transactions Partner Fee list."
        icon="pe-7s-info"
        list={{
          columns: columns,
          reducerPath: "transactions.partnerFee",
          call: getTransactionsPartnerFeeList,
          apiQuery: apiQuery,
          module: TRANSACTIONS_PARTNER_FEE_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Partner_Fee_List',
            apiResponseKey: 'data.partnerFees',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Connected Partner",
              type: PARTNER_SELECT,
              key: "partner",
              value: filter.partner
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
          ]
        }}
      />
    </div>
  );
};

export default Index;
