import { yupResolver } from '@hookform/resolvers';
import { endOfDay, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { businessesApi } from '../../../../../services/businessesApi';
import { merchantApi } from '../../../../../services/merchantApi';
import { BUSINESS_ADMIN_VERIFY_MODULE, BUSINESS_MODULE } from '../../../../../utils/constants/actionTypes';
import { numberRegExp } from '../../../../../utils/constants/regexTypes';
import getCroppedImg, { urlToFile } from '../../../../../utils/helpers/cropImage';
import EvyDatePicker from '../../../../../components/Forms/EvyDatePicker';
import FormField from '../../../../../components/Forms/FormField';
import HookTextField from '../../../../../components/Forms/HookTextField';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import useAsync from '../../../../../components/HooksUse/useAsync';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker';
import PopupCropperKYC from '../../../../../components/PopUp/PopUpCropper';
import UploadPhotoTemplate from '../UploadPhotoOutlet';

const schema = yup.object().shape({
  phoneNumber: yup.string().required('Phon Number is required'),
  fullName: yup.string().required('Fullname is required'),
  identificationNumber: yup.string().required('ID Card Number is required').matches(numberRegExp, 'Must be a Number'),
  placeOfBirth: yup.string().required('Place Of Birth is required'),
  dateOfBirth: yup.string().required('Date Of Birth is required'),
  address: yup.string().required('Address is required'),
});

const convertArrayToObject = (array, key, value) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    };
  }, initialValue);
};

const MerchantOwner = ({ item, module, onSuccess = () => { }, readOnly }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false)
  const [matchStatus, setMatchStatus] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showCropImage, setShowCropImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedPreview, setCroppedPreview] = useState(null)
  const [imageKtp, setImageKtp] = useState(null);
  const [errorMinimumPixels, setErrorMinimumPixels] = useState('');
  const token = window.localStorage.getItem('token');

  const selfieImage = item?.merchant?.archive?.identityCard?.selfPhoto?.url + '?token=' + token;
  const aspect = 3 / 4;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true)
    try {
      const croppedImage = await getCroppedImg(
        selfieImage,
        croppedAreaPixels,
        rotation
      )
      if (croppedImage.error) {
        setErrorMinimumPixels(croppedImage.error)
      } else {
        setCroppedImage(croppedImage.file)
        setCroppedPreview(croppedImage.img)
        setErrorMinimumPixels('')
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, selfieImage])

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const { register, handleSubmit, errors, unregister, watch, getValues, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: item.merchant.phoneNumber,
      fullName: item.merchant.fullName,
      identificationNumber: item.merchant.archive.identityCard.identificationNumber,
      placeOfBirth: item.merchant.placeOfBirth,
      dateOfBirth: item.merchant.dateOfBirth,
      address: item.merchant.address
    }
  });

  const { dateOfBirth, placeOfBirth } = watch(['dateOfBirth', 'placeOfBirth']);

  const onSubmit = (values) => {
    const data = {
      ...values,
      dateOfBirth: formatInTimeZone(endOfDay(new Date(values.dateOfBirth)), 'Asia/Jakarta', 'yyyy-MM-dd HH:mm:ss zzz')
    }
    setIsLoading(true);
    dispatch({ type: null, call: businessesApi.updateOwner, args: [item._id, data] })
      .then(() => {
        if (isMounted()) handleSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  };

  const verify = async () => {
    await showCroppedImage()
    if (croppedImage && imageKtp) {
      const data = {
        nik: getValues("identificationNumber"),
        name: getValues("fullName"),
        birth_place: placeOfBirth,
        birth_date: format(new Date(dateOfBirth), 'dd-MM-yyyy'),
        image: croppedImage,
        businessId: item?._id,
        imageKtp
      }

      setMatchStatus(null);
      return dispatch({ type: null, call: businessesApi.verifyID, args: [data] })
        .then(async res => {
          if (isMounted()) {
            setMatchStatus(convertArrayToObject(res.data, 'fieldName', 'status'));
            handleSuccess();
          }
        })
        .catch(err => {
          if (isMounted()) setMatchStatus(err.message);
        })
    }
  }

  const verifyAsync = useAsync(verify);

  const onVerify = () => {
    verifyAsync.execute()
    setShowCropImage(false)
    setCroppedImage(null)
  }

  const fetchData = useCallback(async () => {
    if (item?.merchant?.archive?.identityCard?.cardPhoto?.url) {
      const ktpImage = await urlToFile(item?.merchant?.archive?.identityCard?.cardPhoto?.url + '?token=' + token, "ktpImage.jpg")

      if (ktpImage) {
        setImageKtp(ktpImage)
      }
    }
  }, [item?.merchant?.archive?.identityCard?.cardPhoto?.url, token])

  useEffect(() => {
    fetchData();

    return () => { }
  }, [fetchData])

  useEffect(() => {
    register("dateOfBirth");

    return () => {
      unregister("dateOfBirth");
    };
  }, [register, unregister]);

  const GetInfo = () => {
    if (errorMinimumPixels) {
      return <p className="text-danger">Pixels harus lebih dari 480 x 640 pixels, mohon untuk zoom out</p>
    } else {
      return <p>Pastikan <b>wajah pengguna</b> memenuhi gambar.</p>
    }
  }

  return (
    <>
      {
        (
          module === BUSINESS_MODULE ||
          module === BUSINESS_ADMIN_VERIFY_MODULE
        )
        &&
        <>
          <button
            className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ?
              <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
              <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
            }
          </button>
          <br /><br />
        </>
      }
      <div className="row">
        <div className="col-12 col-xl-4">
          <UploadPhotoTemplate
            label="Kartu Identitas"
            id={item?.merchant?._id}
            call={merchantApi.uploadIdentificationCard}
            onSuccess={onSuccess}
            previousImageUrl={item?.merchant?.archive?.identityCard?.cardPhoto?.url}
          />

          <a className='btn btn-primary mb-3' target={"_blank"} rel="noreferrer" href={`${process.env.REACT_APP_IMAGE_PREVIEW}/preview?url=${item?.merchant?.archive?.identityCard?.cardPhoto?.url + '?token=' + token}`}>
            Preview Kartu Identitas
          </a>
          <br />
          <UploadPhotoTemplate
            label="Swafoto"
            id={item?.merchant?._id}
            call={merchantApi.uploadSelfPhoto}
            onSuccess={onSuccess}
            previousImageUrl={item?.merchant?.archive?.identityCard?.selfPhoto?.url}
          />
          <a className='btn btn-primary mb-3' target={"_blank"} rel="noreferrer" href={`${process.env.REACT_APP_IMAGE_PREVIEW}/preview?url=${item?.merchant?.archive?.identityCard?.selfPhoto?.url + '?token=' + token}`}>
            Preview Swafoto
          </a>
        </div>
        <div className="col-12 col-xl-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Nomor HP Pemilik"
              name="phoneNumber"
              error={errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Nama Pemilik"
              name="fullName"
              error={errors.fullName}
              helperText={errors.fullName?.message}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Nomor KTP"
              name="identificationNumber"
              error={errors.identificationNumber}
              helperText={errors.identificationNumber?.message}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Tempat Lahir"
              name="placeOfBirth"
              error={errors.placeOfBirth}
              helperText={errors.placeOfBirth?.message}
            />
            {(isEditing ? !isEditing : readOnly) ?
              <HookTextField
                readOnly
                label="Tanggal Lahir"
                value={format(new Date(dateOfBirth), 'yyyy-MM-dd')}
              />
              :
              <FormField label="Tanggal Lahir">
                <EvyDatePicker
                  onChange={(v) => setValue('dateOfBirth', v, { shouldValidate: true })}
                  value={dateOfBirth}
                  error={errors.dateOfBirth}
                  helperText={errors.dateOfBirth?.message}
                  autoOk
                />
              </FormField>
            }
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Alamat sesuai KTP"
              name="address"
              error={errors.address}
              helperText={errors.address?.message}
            />
            {
              matchStatus
              &&
              generateAlert(matchStatus)
            }
            {
              (
                module === BUSINESS_MODULE ||
                module === BUSINESS_ADMIN_VERIFY_MODULE
              )
              &&
              <>
                <LoadingBtn
                  type="submit"
                  disabled={isEditing ? !isEditing : readOnly}
                  loading={isLoading}
                  className="btn btn-primary btn-lg mr-2"
                >
                  Update
                </LoadingBtn>
                {item?.buttonVerifyEnabled &&
                  <button
                    type="button"
                    className="btn btn-primary btn-lg mr-2"
                    onClick={() => setShowCropImage(true)}
                  >
                    Verify ID
                  </button>
                }
              </>
            }
            {
              isEditing
              &&
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="btn btn-danger btn-lg"
              >
                Cancel
              </button>
            }
          </form>
          {item?.usedNikBy?.length ?
            <>
              <hr className="my-4" />
              <div className="p-3 border d-grid gap-8">
                {item?.usedNikBy.map((v, i) => {
                  return (
                    <div className="alert alert-warning font-weight-bold mb-0" key={i}>
                      {v}
                    </div>
                  )
                })}
              </div>
            </>
            :
            null
          }
        </div>
        <PopupCropperKYC
          showCropImage={showCropImage}
          setShowCropImage={setShowCropImage}
          croppedImage={croppedImage}
          croppedPreview={croppedPreview}
          cropperProps={{
            image: selfieImage,
            crop,
            setCrop,
            zoom,
            setZoom,
            rotation,
            setRotation,
            onCropComplete,
            aspect
          }}
          isLoading={isLoading}
          onVerify={onVerify}
          setCroppedImage={setCroppedImage}
          showCroppedImage={showCroppedImage}
          title="Potong Swafoto"
          info={<GetInfo />}
        />
        <LoadingBlocker in={isLoading} />
      </div>
    </>
  )
}

const getAlertVarian = string => {
  let className = '';
  if (string === "match") {
    className = "";
  } else if (string === "partial") {
    className = "text-warning";
  } else if (string === "nomatch") {
    className = "text-danger";
  }
  return className;
}


const generateAlert = (matchStatus) => {
  let html;
  if (typeof matchStatus === 'string') {
    html = (
      <div className="alert alert-danger">
        <b>{matchStatus}</b>
      </div>
    )
  } else {
    html = (
      <div className="alert alert-success">
        <span className="mr-2">NIK: <b className={getAlertVarian(matchStatus.nik)}>{matchStatus.nik}</b>,</span>
        <span className="mr-2">Nama: <b className={getAlertVarian(matchStatus.name)}>{matchStatus.name}</b>,</span>
        <span className="mr-2">Tempat Lahir: <b className={getAlertVarian(matchStatus.birth_place)}>{matchStatus.birth_place}</b>,</span>
        <span className="mr-2">Tanggal Lahir: <b className={getAlertVarian(matchStatus.birth_date)}>{matchStatus.birth_date}</b>,</span>
        <span className="mr-2">Tingkat Kesamaan: <b className={getAlertVarian(matchStatus.similarity)}>{matchStatus.similarity}</b>,</span>
      </div>
    );
  }
  return html;
}

export default MerchantOwner
