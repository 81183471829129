import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TOP_UP_MODULE } from "../../../utils/constants/actionTypes";
import { combineBy } from "../../../utils/helpers/combineBy";
import { DATE_PICKER, PARTNER_SELECT, SELECT_FIELD, TEXT_FIELD, USER_SELECT } from "../../../utils/constants/inputTypes";
import { toIDR } from "../../../utils/helpers/currency";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import DetailTab from "./DetailTab";
import { topUpApi } from "../../../services/topUpApi";
import { partnerTypes } from "../../../utils/constants/enums/partnerTypes";
import styles from './list.module.scss'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RoundBadge from '../../../components/Badge/RoundBadge';

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalAmount
  } = useSelector(state => state.topUp);

  const listApiDebounce = useMemo(
    () => AwesomeDebouncePromise(topUpApi.list, 500),
    [],
  )

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd HH:mm');
      }
    },
    {
      title: 'Code', key: 'code', name: 'code', render: (v, row) => {
        return (
          <div>
            <span>{v}</span>
            {row?.limitExceeded?.primary || row?.limitExceeded?.cummulative ?
              <div className="badge badge-danger">
                Limit Exceeded
              </div>
              : null
            }
          </div>
        )
      }
    },
    {
      title: 'Connected Partner', key: 'applicant.connectedPartner', name: 'applicant.connectedPartner', render: (v) => (
        <div>
          {v?.length ? v.map((partner, index) => (
            <div key={index} className="d-flex align-items-center my-1">
              <span className='mr-1'>{partnerTypes.getStr(partner.companyCode)}</span>
              {partner?.privateUser ?
                <div className={styles.badge}>
                  <span>Private</span>
                </div>
                : null
              }
            </div>
          )) : '-'}
        </div>
      )
    },
    { title: 'Desc', key: 'description', name: 'description' },
    {
      title: 'Applicant', key: 'applicant', name: 'applicant', render: (v) => {
        return (
          <div className={styles.applicant}>
            <RoundBadge
              title={v.status?.upgraded ? `KYC Verified at: ${format(new Date(v.archive.identityCard.updatedAt), 'yyyy-MM-dd HH:mm:ss')}` : v.kycProgress?.isForce ? v.kycProgress?.forcedAt ? `Forced KYC at: ${format(new Date(v.kycProgress?.forcedAt), 'yyyy-MM-dd HH:mm:ss')} ` : 'Forced KYC' : 'Not Upgraded'}
              active={v.status?.upgraded || v.kycProgress?.isForce}
              variant={v.status?.upgraded ? 'primary' : 'danger'}
              icon={VerifiedUserIcon}
            />
            <span>{combineBy([v.firstName, v.lastName])} | {v.uniqueId}</span>
          </div>
        )
      }
    },
    { title: 'Amount', key: 'amount', name: 'amount', render: v => toIDR(v) },
    { title: 'Status', key: 'status', name: 'status' }
  ];

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.user && typeof filter.user === 'object' ? `applicantModel|User,applicant|${filter.user.value}` : false,
      filter.transactionCode ? `code|${filter.transactionCode} ` : false
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const source = filter.source;
    const status = filter.upgraded;

    return {
      page: currentPage,
      limit,
      sort,
      search,
      startDate,
      endDate,
      partner: filter.connectedPartner,
      source,
      status
    }
  }, [filter, currentPage, limit, sort])

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Code', key: 'code', name: 'code' },
    {
      title: 'Connected Partner', key: 'applicant.connectedPartner', name: 'applicant.connectedPartner', render: (v) => (
        v?.length ? v.map((partner, index) => (
          <div key={index}>
            {partnerTypes.getStr(partner.companyCode)}{" "}
            {partner?.privateUser ?
              "(Private)"
              : ""
            }
            {(v.length - 1) - index ? ", " : ""}
          </div>
        )) : '-'
      )
    },
    { title: 'Desc', key: 'description', name: 'description' },
    { title: 'Applicant', key: 'applicant', name: 'applicant', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId} ` },
    { title: 'Amount', key: 'amount', name: 'amount', render: v => toIDR(v, false) },
    { title: 'Status', key: 'status', name: 'status' }
  ], [])

  return (
    <div>
      <Resource
        title="Top Up"
        subTitle="Pending Top Up list."
        icon="pe-7s-cloud-upload"
        list={{
          columns: columns,
          reducerPath: "topUp",
          call: listApiDebounce,
          apiQuery: apiQuery,
          module: TOP_UP_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Top_Up_List',
            apiResponseKey: 'data.topups',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter User Status",
              type: SELECT_FIELD,
              key: "upgraded",
              options: [
                { value: "UPGRADED", label: "Upgraded" },
                { value: "NOT_UPGRADED", label: "Not Upgraded" }
              ],
              value: filter.upgraded
            },
            {
              label: "Filter Transaction Code",
              type: TEXT_FIELD,
              key: "transactionCode",
              value: filter.transactionCode,
              placeholder: "Search code"
            },
            {
              label: "Filter Source",
              type: SELECT_FIELD,
              key: "source",
              options: [
                { label: 'Bank BCA', value: 'BCA' },
                { label: 'CIMB Niaga', value: 'BNIA' },
                { label: 'Bank Maybank', value: 'IBBK' },
                { label: 'Bank Permata', value: 'BBBA' },
                { label: 'Bank Mandiri', value: 'BMRI' },
                { label: 'Bank BRI', value: 'BRIN' },
                { label: 'Bank Danamon', value: 'BDIN' },
                { label: 'Bank KEB Hana', value: 'HNBN' },
                { label: 'Indofund', value: 'INDOFUND' }
              ],
              value: filter.source,
            },
            {
              type: USER_SELECT,
              key: "user",
              value: filter.user,
              selectProps: {
                search: (search) => `fullName|${search},uniqueId|${search},phoneNumber|${search}`,
                label: (user) => `${combineBy([user.firstName, user.lastName])} | ${user.uniqueId} | ${user.phoneNumber}`
              }
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
            {
              label: "Filter Connected Partner",
              type: PARTNER_SELECT,
              key: "connectedPartner",
              value: filter.connectedPartner || "",
              getOptionValue: v => v.optData.companyCode,
              filterIntegrationType: "COMPANY",
              filterByCompanyCode: true
            },
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
