import React, { useCallback, useMemo, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import { format } from 'date-fns'
import { combineBy } from '../../../../../utils/helpers/combineBy';
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { toIDR } from '../../../../../utils/helpers/currency';
import { TableSortLabel } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { redisApi } from '../../../../../services/redisApi';
import { REPORT_MODULE, RESET_CACHE } from '../../../../../utils/constants/actionTypes';
import { toast } from 'react-toastify';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import getPropByString from '../../../../../utils/helpers/getPropByString';
import { ExcelIcon } from '../../../../../components/Icons/Icons';
import ExportExcelToast from '../../../../../components/templates/ExportExcelToast';
import { auditLogApi } from '../../../../../services/auditLogApi';
import ExcelTable from './ExcelTable';
import RowRender from './RowRender';

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)));
const excelFormat = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const ReportTableTemplateV2 = ({
  table,
  withIndex,
  period,
  title,
  withResetCache,
  dataPosition,
  withOptionsButton,
  withExportExcel,
  withPrintButton,
  notes,
  excel
}) => {
  const componentRef = useRef();
  const tableFooterTotalRef = useRef();
  const tableFooterPrintRef = useRef();
  const tableHeaderPrintRef = useRef();
  const tableHeaderRef = useRef();
  const dispatch = useDispatch();
  const [resetting, setResetting] = useState(false)
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('desc');
  const [option, setOption] = useState('');

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      tableFooterPrintRef.current.style.display = "table-row";
      tableFooterTotalRef.current.style.display = "none";
      tableHeaderRef.current.style.display = "none";
      tableHeaderPrintRef.current.style.display = "flex";
    },
    content: () => componentRef.current,
    onAfterPrint: () => {
      tableFooterPrintRef.current.style.display = "none"
      tableFooterTotalRef.current.style.display = "table-footer-group"
      tableHeaderRef.current.style.display = "flex";
      tableHeaderPrintRef.current.style.display = "none";
    }
  })

  const getNumberOfHiddenColumn = useMemo(() => {
    const filteredColumn = table.columns.filter(row => row.hide && row.hide === true);
    return filteredColumn.length
  }, [table])

  const resetCache = () => {
    setResetting(true)
    dispatch({
      type: null,
      call: redisApi.reset,
    })
      .then(() => dispatch({
        module: REPORT_MODULE,
        type: RESET_CACHE
      }))
      .then(() => toast('Successfully Reset Cache', { type: "success" }))
      .catch(() => { })
      .finally(() => setResetting(false))
  }

  const setSort = property => e => {
    const isDesc = orderBy === property && order === "desc";

    setOrderBy(property);
    setOrder(isDesc ? "asc" : "desc");
  };

  const totalSpan = useMemo(() => {
    if (!withIndex && table.grandTotal && !table.grandTotal.length) {
      return (table.columns.length - 1) - getNumberOfHiddenColumn
    } else if (!withIndex && table.grandTotal && table.grandTotal.length) {
      return (table.columns.length - table.grandTotal.length) - getNumberOfHiddenColumn
    } else if (withIndex && table.grandTotal && table.grandTotal.length) {
      return (table.columns.length - (table.grandTotal.length - 1)) - getNumberOfHiddenColumn
    } else {
      return table.columns.length
    }
  }, [table.grandTotal, table.columns, withIndex, getNumberOfHiddenColumn]);

  const withNotes = useMemo(() => {
    if (notes && notes.length) {
      return (
        <div>
          {notes.map((v, i) => {
            return (
              <div key={i}>- {v}</div>
            )
          })
          }
        </div>
      )
    }

    return null
  }, [notes])

  const exportExcel = useCallback(() => {
    const filename = `${combineBy(title.split(' '), '_')}_${format(new Date(period[0]), 'yyyy-MM-dd')}_${format(new Date(period[1]), 'yyyy-MM-dd')}.xls`
    const excelHref = 'data:application/vnd.ms-excel;base64,';
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{html}</body></html>';

    const toastId = toast(<ExportExcelToast filename={filename} progress={0} />, {
      progress: 0,
      autoClose: false,
      closeButton: false,
      draggable: false,
      closeOnClick: false,
      type: "info"
    })

    if (Boolean(excel)) {
      dispatch({
        type: null,
        call: excel.service,
        args: [excel.queryParams]
      })
        .then((res) => {
          toast.update(toastId, {
            render: <ExportExcelToast filename={filename} progress={1} />,
            progress: 1
          })

          const items = getPropByString(res, excel.apiResponseKey);
          const html = ReactDOMServer.renderToStaticMarkup(ExcelTable(
            {
              withIndex,
              columns: table.columns,
              data: items,
              grandTotal: table.grandTotal,
              totalSpan,
              withNotes
            }
          ))

          const context = {
            worksheet: 'Worksheet',
            html,
          };

          dispatch({
            type: null,
            call: auditLogApi.create,
            args: [filename, 'GET', 'Export Excel All']
          }).finally(() => {
            let a = document.createElement('a')
            a.href = excelHref + base64(excelFormat(template, context));
            a.download = filename
            a.click()
          })
        })
        .catch(() => toast.dismiss(toastId))
    } else {
      toast.update(toastId, {
        render: <ExportExcelToast filename={filename} progress={1} />,
        progress: 1
      })
      const html = ReactDOMServer.renderToStaticMarkup(ExcelTable(
        {
          withIndex,
          columns: table.columns,
          data: table.data,
          grandTotal: table.grandTotal,
          totalSpan,
          withNotes
        }
      ))

      const context = {
        worksheet: 'Worksheet',
        html,
      };

      dispatch({
        type: null,
        call: auditLogApi.create,
        args: [filename, 'GET', 'Export Excel All']
      }).finally(() => {
        let a = document.createElement('a')
        a.href = excelHref + base64(excelFormat(template, context));
        a.download = filename
        a.click()
      })
    }


  }, [dispatch, period, table, excel, title, totalSpan, withIndex, withNotes]);

  return table.data && table.data.length ?
    <>
      <div className="card">
        <div className="table-header" ref={tableHeaderRef}>
          <div className="info-cell">
            <div className="d-flex justify-content-between align-items-center">
              <div>{title}</div>
              <div>
                {(period && period.length) && (
                  <div>
                    {format(new Date(period[0]), 'yyyy-MM-dd')} / {format(new Date(period[1]), 'yyyy-MM-dd')}
                  </div>
                )}
                {dataPosition &&
                  <div>Data Position: {dataPosition}</div>
                }
                <div className="d-flex">
                  {withPrintButton &&
                    <button className="btn btn-light w-100" onClick={handlePrint}>
                      Print
                      <FontAwesomeIcon className="ml-2" icon={faPrint} size="1x" />
                    </button>
                  }
                  {withOptionsButton &&
                    <div className="btn-group ml-4">
                      {withOptionsButton.map((v, i) => {
                        return (
                          <button
                            key={i}
                            onClick={() => {
                              if (typeof v.response === "function") {
                                v.response(v.label)
                              }
                              setOption(v.label)
                            }}
                            className={`btn btn-mutasi-type ${option === v.label ? ' active' : ''}`}
                          >
                            {v.label}
                          </button>
                        )
                      })
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {withResetCache &&
            <div className="d-flex">
              <LoadingBtn
                className="btn btn-danger b-radius-0"
                onClick={resetCache}
                loading={resetting}
              >
                Reset Cache
              </LoadingBtn>
            </div>
          }
          {withExportExcel &&
            <div className="action-cell">
              <button
                onClick={exportExcel}
                className="btn btn-excel"
              >
                <ExcelIcon size={32} />
                <span className="ml-2">Export to Excel</span>
              </button>
            </div>
          }
        </div>

        {(table.columns && table.columns.length) ?
          <div className="report-table-container">
            <div ref={componentRef}>
              <div className="table-header" ref={tableHeaderPrintRef} style={{ display: 'none' }}>
                <div className="info-cell">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{title}</div>
                    <div>
                      {(period && period.length) && (
                        <div>
                          {format(new Date(period[0]), 'yyyy-MM-dd')} / {format(new Date(period[1]), 'yyyy-MM-dd')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Table stickyHeader className="sticky-footer">
                <TableHead>
                  <TableRow>
                    {withIndex && <TableCell>#</TableCell>}
                    {table.columns.map((col, key) => {
                      return !col.hide ?
                        <TableCell key={key} align={col.align}>
                          {col?.sortable ?
                            <TableSortLabel
                              active={orderBy === col?.key}
                              direction={order}
                              onClick={setSort(col?.key)}
                            >
                              {col.title}
                            </TableSortLabel>
                            : col.title
                          }
                        </TableCell>
                        : null
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    stableSort(table.data, getSorting(order, orderBy)).map((row, key) => (
                      <RowRender
                        row={row}
                        key={key}
                        index={key}
                        withIndex={withIndex}
                        columns={table.columns}
                      />
                    ))
                  }
                  {table.grandTotal && table.grandTotal.length ?
                    <TableRow ref={tableFooterPrintRef} style={{ display: 'none' }}>
                      <TableCell
                        component="th"
                        align="right"
                        colSpan={totalSpan}
                      >
                        <span className="font-weight-semibold">Total All Data</span>
                      </TableCell>
                      {table.grandTotal.map((v, i) =>
                        <TableCell component="th" key={i}>
                          <span className="font-weight-semibold">{toIDR(v.value, true)}</span>
                        </TableCell>
                      )}
                    </TableRow>
                    :
                    null
                  }
                </TableBody>
                {table.grandTotal && table.grandTotal.length ?
                  <TableFooter ref={tableFooterTotalRef}>
                    <TableRow>
                      <TableCell
                        component="th"
                        align="right"
                        colSpan={totalSpan}
                      >
                        <span className="font-weight-semibold">Total All Data</span>
                      </TableCell>
                      {table.grandTotal.map((v, i) =>
                        <TableCell component="th" key={i}>
                          <span className="font-weight-semibold">{toIDR(v.value, true)}</span>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableFooter>
                  :
                  null
                }
              </Table>
            </div>
          </div>
          :
          <TableBody>
            <TableRow className="d-flex justify-content-center">
              <TableCell>No Data</TableCell>
            </TableRow>
          </TableBody>
        }
      </div>
      {
        withNotes &&
        <div className="card p-4">
          <h6 className="font-weight-bold">Notes:</h6>
          {withNotes}
        </div>
      }
    </>
    :
    <h5 className="mb-0 px-3 py-4 text-center">No Data</h5>
}

export default ReportTableTemplateV2
