export const APP_LOAD = 'APP_LOAD';
export const LOGIN = 'LOGIN';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RESEND_OTP = 'RESEND_OTP';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const CLEAN_AUTH_STATE = 'CLEAN_AUTH_STATE';
export const SET_MATCH_STATUS = 'SET_MATCH_STATUS';

export const REFERRED_MODULE = 'REFERRED_MODULE';

export const OUTLET_MODULE = 'OUTLET_MODULE';
export const EMPLOYEE_MODULE = 'EMPLOYEE_MODULE';

export const ADMIN_MODULE = 'ADMIN_MODULE';
export const BANK_MODULE = 'BANK_MODULE';
export const BANK_ACCOUNT_MODULE = 'BANK_ACCOUNT_MODULE';
export const ERROR_LOG_MODULE = 'ERROR_LOG_MODULE';
export const BUSINESS_MODULE = 'BUSINESS_MODULE';
export const KYC_UPGRADES_MODULE = 'KYC_UPGRADES_MODULE';
export const KYC_HISTORIES_MODULE = 'KYC_HISTORIES_MODULE';
export const KYC_VERIFY_HISTORIES_MODULE = 'KYC_VERIFY_HISTORIES_MODULE';
export const USER_MODULE = 'USER_MODULE';
export const REQUEST_UPDATE_USER_MODULE = 'REQUEST_UPDATE_USER_MODULE';
export const REQUEST_UPDATE_BUSINESS_MODULE = 'REQUEST_UPDATE_BUSINESS_MODULE';
export const PARTNER_MODULE = 'PARTNER_MODULE';
export const PARTNER_SETTLEMENT_MODULE = 'PARTNER_SETTLEMENT_MODULE';
export const MERCHANT_MODULE = 'MERCHANT_MODULE';
export const VOUCHER_MODULE = 'VOUCHER_MODULE';
export const BUSINESS_REFERRAL_MODULE = 'BUSINESS_REFERRAL_MODULE';
export const CAREER_MODULE = 'CAREER_MODULE';
export const QRIS_RECON_MODULE = 'QRIS_RECON_MODULE';
export const CONFIG_MODULE = 'CONFIG_MODULE';
export const BISNIS_APP_MODULE = 'BISNIS_APP_MODULE';
export const USER_APP_MODULE = 'USER_APP_MODULE';
export const BLOG_APP_MODULE = 'BLOG_APP_MODULE';

export const BISNIS_ANNOUNCEMENT_APP_MODULE = 'BISNIS_ANNOUNCEMENT_APP_MODULE';
export const USER_ANNOUNCEMENT_APP_MODULE = 'USER_ANNOUNCEMENT_APP_MODULE';

export const IMAGE_TEMP_MODULE = 'IMAGE_TEMP_MODULE';
export const TOP_UP_MODULE = 'TOP_UP_MODULE';
export const SETTLEMENT_MODULE = 'SETTLEMENT_MODULE';
export const PPOB_MODULE = 'PPOB_MODULE';
export const CHECK_PPOB_PRICE_MODULE = 'CHECK_PPOB_PRICE_MODULE';
export const TRANSACTIONS_QRIS_MPM_MODULE = 'TRANSACTIONS_QRIS_MPM_MODULE';
export const TRANSACTIONS_QRIS_CPM_MODULE = 'TRANSACTIONS_QRIS_CPM_MODULE';
export const TRANSACTIONS_QRIS_TUNTAS_MODULE = 'TRANSACTIONS_QRIS_TUNTAS_MODULE';
export const TRANSACTIONS_MERCHANT_MODULE = 'TRANSACTIONS_MERCHANT_MODULE';
export const TRANSACTIONS_MDR_MODULE = 'TRANSACTIONS_MDR_MODULE';
export const TRANSACTIONS_PPOB_MODULE = 'TRANSACTIONS_PPOB_MODULE';
export const TRANSACTIONS_PARTNER_MODULE = 'TRANSACTIONS_PARTNER_MODULE';
export const TRANSACTIONS_PARTNER_FEE_MODULE = 'TRANSACTIONS_PARTNER_FEE_MODULE';
export const TRANSACTIONS_CASHBACK_MODULE = 'TRANSACTIONS_CASHBACK_MODULE';
export const TRANSACTIONS_REWARD_MODULE = 'TRANSACTIONS_REWARD_MODULE';
export const TRANSFER_DIPAY_MODULE = 'TRANSFER_DIPAY_MODULE';
export const TRANSFER_BANK_MODULE = 'TRANSFER_BANK_MODULE';
export const TRANSACTIONS_REFUNDS_MODULE = 'TRANSACTIONS_REFUNDS_MODULE';
export const TRANSACTIONS_TOPUP_TRANSFER_PARTNER_MODULE = 'TRANSACTIONS_TOPUP_TRANSFER_PARTNER_MODULE';

export const NOTIFICATION_MODULE = 'NOTIFICATION_MODULE';
export const REFERRAL_MODULE = 'REFERRAL_MODULE';
export const REPORT_MODULE = 'REPORT_MODULE';
export const FLAGGED_MODULE = 'FLAGGED_MODULE';
export const CLOSED_USER_ACCOUNT_MODULE = 'CLOSED_USER_ACCOUNT_MODULE';
export const CLOSED_MERCHANT_ACCOUNT_MODULE = 'CLOSED_MERCHANT_ACCOUNT_MODULE';
export const INJECTION_MODULE = 'INJECTION_MODULE';
export const DTTOT_MODULE = 'DTTOT_MODULE';
export const NNS_MODULE = 'NNS_MODULE';
export const WITHDRAW_MODULE = 'WITHDRAW_MODULE';
export const AUDIT_LOG_MODULE = 'AUDIT_LOG_MODULE';
export const CASHBACK_MODULE = 'CASHBACK_MODULE';
export const REJECT_REASON_MODULE = 'REJECT_REASON_MODULE';
export const NEWS_MODULE = 'NEWS_MODULE';
export const DEDUCTION_MODULE = 'DEDUCTION_MODULE';
export const TRANSACTION_LOG_MODULE = 'TRANSACTION_LOG_MODULE';
export const AUTO_DEBIT_MODULE = 'AUTO_DEBIT_MODULE';
export const DISCLAIMER_MODULE = 'DISCLAIMER_MODULE';
export const DONATION_MODULE = 'DONATION_MODULE';
export const DONATION_NEWS_MODULE = 'DONATION_NEWS_MODULE';
export const AUTO_DEBIT_LOG_MODULE = 'AUTO_DEBIT_LOG_MODULE';
export const INJECTION_BULKING_MODULE = 'INJECTION_BULKING_MODULE';
export const COMPANY_MODULE = 'COMPANY_MODULE';
export const BANK_TRANSFER_FEE_MODULE = 'BANK_TRANSFER_FEE_MODULE';
export const PARTNER_USER_MODULE = 'PARTNER_USER_MODULE';
export const PROMO_CONFIG_MODULE = 'PROMO_CONFIG_MODULE';
export const PROMO_QRIS_CONFIG_MODULE = 'PROMO_QRIS_CONFIG_MODULE';
export const BUSINESS_ADMIN_PTEN_VERIFY_MODULE = 'BUSINESS_ADMIN_PTEN_VERIFY_MODULE';
export const BUSINESS_ADMIN_VERIFY_MODULE = 'BUSINESS_ADMIN_VERIFY_MODULE';
export const BUSINESS_SETTLEMENT_MODULE = 'BUSINESS_SETTLEMENT_MODULE';
export const LIST_SETTLEMENT_TAB_LOAD = 'LIST_SETTLEMENT_TAB_LOAD';
export const LIST_SETTLEMENT_REJECTED = 'LIST_SETTLEMENT_REJECTED';
export const PRINT_REPORT = 'PRINT_REPORT'

export const OPEN_API_SETTING_MODULE = 'OPEN_API_SETTING_MODULE';

export const MAIN_TAB_LOAD = 'MAIN_TAB_LOAD';
export const POSITION_TAB_LOAD = 'POSITION_TAB_LOAD';
export const MAIN_TAB_UNLOAD = 'MAIN_TAB_UNLOAD';
export const FORM_TAB_LOAD = 'FORM_TAB_LOAD';
export const VIEW_DETAIL = 'VIEW_DETAIL';
export const GET_DETAIL = 'GET_DETAIL';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';
export const EDIT = 'EDIT';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const MINOR_UPDATE = 'MINOR_UPDATE';
export const CLEAR_FORM = 'CLEAR_FORM';
export const ADD_DELETE_DATA = 'ADD_DELETE_DATA';
export const REMOVE_DELETE_DATA = 'REMOVE_DELETE_DATA';
export const DELETE = 'DELETE';
export const CHANGE_TAB = 'CHANGE_TAB';
export const CREATE_TAB = 'CREATE_TAB';
export const CREATE_TAB_LOAD = 'CREATE_TAB_LOAD';
export const CLOSE_TAB = 'CLOSE_TAB';
export const SORT_TABLE = 'SORT_TABLE';
export const SEARCH_TABLE = 'SEARCH_TABLE';
export const FILTER_TABLE = 'FILTER_TABLE';
export const FILTER_RESULT = 'FILTER_RESULT';
export const LIMIT_TABLE = 'LIMIT_TABLE';
export const SET_PAGE_TABLE = 'SET_PAGE_TABLE';
export const APPROVE_OR_REJECT = 'APPROVE_OR_REJECT';
export const INIT_SOCKET = 'INIT_SOCKET';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const GET_TOTAL_USERS = 'GET_TOTAL_USERS';
export const GET_TOTAL_BUSINESSES = 'GET_TOTAL_BUSINESSES';
export const GET_TOTAL_COMPLETED_TRANSACTIONS = 'GET_TOTAL_COMPLETED_TRANSACTIONS';
export const GET_TOTAL_HOLDED_CASH = 'GET_TOTAL_HOLDED_CASH';
export const GET_TOTAL_MERCHANT_FLOAT = 'GET_TOTAL_MERCHANT_FLOAT';
export const SET_FROM_DATE_FILTER = 'SET_FROM_DATE_FILTER';
export const SET_TO_DATE_FILTER = 'SET_TO_DATE_FILTER';
export const DERIVE = 'DERIVE';
export const CLEAR_DERIVE = 'CLEAR_DERIVE';
export const RESET_CACHE = 'RESET_CACHE';
export const EXPORT_EXCEL_ALL = 'EXPORT_EXCEL_ALL';

export const ARTICLE_MODULE = 'ARTICLE_MODULE';
export const FILTER_POSITION = 'FILTER_POSITION';

export const IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  // "image/svg+xml"
];

export const EXCEL_FORMATS = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  ".csv",
];
