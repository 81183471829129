import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import { APPROVE_OR_REJECT, INJECTION_BULKING_MODULE } from '../../../utils/constants/actionTypes';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { toIDR } from '../../../utils/helpers/currency';
import { injectionBulkingApi } from '../../../services/injectionBulkingApi';

const DetailTab = ({ index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { item, key } = useSelector(state => state.injectionBulking.tabs[index]);

  const getClass = status => {
    let className = 'badge-primary';
    if (status === 'PENDING') {
      className = 'badge-warning'
    } else if (status === 'COMPLETED') {
      className = 'badge-success'
    } else if (status === 'FAILED') {
      className = 'badge-danger'
    }
    return className
  }

  const renderTableBody = useMemo(() => {
    let component = []
    let amount = 0
    item?.data?.forEach((row, i) => {
      amount += +row[2]
      component.push(
        <TableRow key={i}>
          <TableCell>{row[0]}</TableCell>
          <TableCell>{row[4]}</TableCell>
          <TableCell>{row[1]}</TableCell>
          <TableCell>{toIDR(row[2])}</TableCell>
          <TableCell>{row[3]}</TableCell>
        </TableRow>
      )
    })
    return {
      component,
      amount
    }
  }, [item])

  const approve = () => {
    setIsLoading(true);
    dispatch({
      module: INJECTION_BULKING_MODULE,
      type: APPROVE_OR_REJECT,
      call: injectionBulkingApi.approve,
      args: [key],
      tabKey: index
    })
      .catch(() => { });
  };

  const reject = (_, remarks) => {
    setIsLoading(true);
    dispatch({
      module: INJECTION_BULKING_MODULE,
      type: APPROVE_OR_REJECT,
      call: injectionBulkingApi.reject,
      args: [key, remarks],
      tabKey: index
    })
      .catch(() => { });
  };

  return item ?
    <div className="row">
      <div className="col-12 col-md-3">
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group">
              <label className="text-grey mb-1">Description</label>
              <p className="mb-0 font-weight-semibold">{item.description ? item.description : '-'}</p>
            </div>
            <div className="form-group">
              <label className="text-grey mb-1">Status</label>
              <span className={`badge ${getClass(item.status)}`}>{item.status}</span>
            </div>
            <div className="form-group">
              <label className="text-grey mb-1">Total Amount</label>
              <p className="mb-0 font-weight-semibold">{toIDR(renderTableBody.amount)}</p>
            </div>
            {
              item.status === 'FAILED' &&
              <div className="form-group">
                <label className="text-grey mb-1">Remarks</label>
                <p className="mb-0 font-weight-semibold">{item.remarks ? item.remarks : '-'}</p>
              </div>
            }
            {
              item.status === 'PENDING' &&
              <div className="mt-5">
                <DeclineDialog
                  className="btn btn-outline-danger mr-2"
                  label="Reject"
                  desc="Please fill reject reason clearly"
                  fullWidth={true}
                  maxWidth="sm"
                  dataId={item._id}
                  onSubmit={reject}
                />
                <LoadingBtn
                  className="btn btn-primary"
                  type="button"
                  onClick={approve}
                >
                  Approve
                </LoadingBtn>
                <LoadingBlocker in={isLoading} />
              </div>
            }
          </div>
        </div>
      </div>
      <div className="col-12 col-md-9">
        <div className="card">
          <div className="table-header">
            <div className="flex-grow-1 flex-shrink-1 bg-light p-3">
              <h5 className="card-title mb-0">Data</h5>
            </div>
          </div>
          <div className="report-table-container">
            <Table stickyHeader className="sticky-footer">
              <TableHead>
                <TableRow>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableBody.component}
              </TableBody>
            </Table>
          </div>
        </div >
      </div>
    </div>
    :
    <div className="card">
      <div className="card-body">
        <h5 className="text-center mb-0">Not Found</h5>
      </div>
    </div>
}

export default DetailTab
