import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TRANSACTIONS_PARTNER_MODULE } from "../../../utils/constants/actionTypes";
import DetailTab from "./DetailTab";
import { combineBy } from "../../../utils/helpers/combineBy";
import { paymentApi } from "../../../services/paymentApi";
import { toIDR } from "../../../utils/helpers/currency";
import AllStatus from "../../../utils/constants/enums/status";
import OrderStatusOpts from '../../../utils/constants/enums/status/orderStatusOpts';
import { DATE_PICKER, MERCHANT_SELECT, PARTNER_SELECT, SELECT_FIELD, USER_SELECT } from "../../../utils/constants/inputTypes";

const optionAll = { value: 'all', label: 'SELECT ALL' }

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalAmount
  } = useSelector(state => state.transactions.partner);

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
      }
    },
    {
      title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: (v, row) => {
        const combine = combineBy([v?.firstName, v?.lastName]);
        return combine ? `${combine} | ${v?.uniqueId} (${row.applicantModel})` : '-';
      }
    },
    { title: 'Amount', key: 'amount', name: 'amount', render: v => toIDR(v) },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) },
    { title: 'Partner', key: 'partner', name: 'partner', render: (v, row) => row?.metadata?.partner?.name }
  ];

  const apiQuery = useMemo(() => {
    // const search = filter.user && typeof filter.user === 'object' ? filter.user?.value === 'all' ? 'applicantModel|User' : `applicantModel|User,applicant|${filter.user.value}` : filter.merchant && typeof filter.merchant === 'object' ? filter.merchant?.value === 'all' ? 'applicantModel|Merchant' : `applicantModel|Merchant,applicant|${filter.merchant.value}` : false
    const search = filter.user && typeof filter.user === 'object' ? `applicantModel|User,applicant|${filter.user.value}` : filter.merchant && typeof filter.merchant === 'object' ? `applicantModel|Merchant,applicant|${filter.merchant.value}` : false
    const partner = filter.partner && typeof filter.partner === 'object' ? `${filter.partner.value}` : false
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      type: filter.type,
      search,
      startDate,
      endDate,
      status: filter.status,
      partner
    }
  }, [filter, currentPage, limit, sort])

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm') },
    {
      title: 'Applicant', key: 'applicant', render: (v, row) => {
        const combine = combineBy([v?.firstName, v?.lastName]);
        return combine ? `${combine} | ${v?.uniqueId}` : '-';
      }
    },
    { title: 'Amount', key: 'amount', render: (v) => v },
    { title: 'Status', key: 'status', render: v => AllStatus.getStr(v) },
    { title: 'Partner', key: 'partner', name: 'partner', render: (v, row) => row?.metadata?.partner?.name },
  ], [])

  return (
    <div>
      <Resource
        title="Transactions Partner"
        subTitle="Full Transactions Partner list."
        icon="pe-7s-ribbon"
        list={{
          columns: columns,
          reducerPath: "transactions.partner",
          call: paymentApi.list,
          apiQuery: apiQuery,
          module: TRANSACTIONS_PARTNER_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Partner_List',
            apiResponseKey: 'data.payments',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: OrderStatusOpts,
              value: filter.status,
            },
            {
              label: "Filter Connected Partner",
              type: PARTNER_SELECT,
              key: "partner",
              value: filter.partner,
              filterIntegrationType: "COMPANY"
            },
            {
              type: USER_SELECT,
              key: "user",
              value: filter.user,
              options: [{ ...optionAll, model: 'USER' }]
            },
            {
              type: MERCHANT_SELECT,
              key: "merchant",
              value: filter.merchant,
              options: [{ ...optionAll, model: 'MERCHANT' }]
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
