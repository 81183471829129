import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { combineBy } from '../../utils/helpers/combineBy';
import useMountedState from '../HooksUse/useMountedState';
import SelectField from '../Forms/SelectField';
import { merchantApi } from '../../services/merchantApi';

const staticValues = {
  sort: 'firstName|asc',
  limit: 10,
}

const MerchantSelect = ({
  label,
  disabled,
  error,
  helperText,
  onChange,
  value,
  customOptions = [],
  ...rest
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [options, setOptions] = useState([])
  const [selectProps] = useState({ ...rest?.selectProps })
  const [customOpts,] = useState(customOptions)
  const [search, setSearch] = useState('')
  const [totalItems, setTotalItems] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const { sort, limit } = staticValues;
  const isMounted = useMountedState();
  const [initialValue] = useState(value)

  const getMerchantListDebounced = useMemo(
    () => AwesomeDebouncePromise(merchantApi.search, 500),
    [],
  )

  const dispatchLoad = useCallback(
    (call, page, limit, sort, search, searchByID, selectProps) => {
      const { search: searchBy } = selectProps;
      return dispatch({
        type: null,
        call: call,
        args: [
          {
            page,
            limit,
            sort: search ? null : sort,
            search: searchByID ? `_id|${searchByID}` : (searchBy && search) ? searchBy(search) : search ? `businesses|${search},fullName|${search},uniqueId|${search}` : null
          }
        ]
      })
    },
    [dispatch],
  )

  const generateOpts = (merchants, labelBy) => merchants.map(merchant => ({
    value: merchant._id,
    label: labelBy ? labelBy(merchant) : `${combineBy([merchant.firstName, merchant.lastName])} | ${merchant.uniqueId ?? '-'} | ${merchant?.businesses?.name}`,
    model: 'Merchant',
    optData: merchant
  }))

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);
      dispatchLoad(merchantApi.search, newPage, limit, sort, search, null, selectProps)
        .then(({ data: { merchants, count } }) => {
          if (isMounted()) {
            const { label: labelBy } = selectProps;
            setOptions([...options, ...generateOpts(merchants, labelBy)]);
            setTotalItems(count);
          }
        })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) });
    }
  }

  const handleSelectChange = opt => {
    setSelectedOption(opt)
    setSearch('')

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(getMerchantListDebounced, newPage, limit, sort, search, null, selectProps)
        .then(({ data: { merchants, count } }) => {
          if (isMounted()) {
            const { label: labelBy } = selectProps;
            options = [...customOpts, ...generateOpts(merchants, labelBy)]
            setTotalItems(count)
            const initial = typeof initialValue === 'object' ? initialValue.value : initialValue
            let selectionExist = Boolean(merchants.find(v => v._id === initial))
            if (initial && !search && !selectionExist) return dispatchLoad(getMerchantListDebounced, 0, 0, null, null, initial)
          }
        })
        .then(({ data: { merchants } = {} } = {}) => {
          if (isMounted() && merchants) options = [...generateOpts(merchants), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setOptions(options)
            setIsLoading(false)
          }
        });
    };

    loadData()
  }, [search, sort, limit, dispatchLoad, getMerchantListDebounced, customOpts, isMounted, initialValue, selectProps])

  return (
    <SelectField
      id="selectMerchant"
      label={label ?? "Select Merchant"}
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder="Type anything.."
      componentProps={{
        inputValue: search,
        onInputChange: setSearch,
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom,
      }}
      isDisabled={disabled}
      error={error}
      helperText={helperText}
    />
  )
}

export default MerchantSelect
