class BankNameType {
  static BCA = 'BCA';
  static BNI = 'BNI';
  static BRI = 'BRI';
  static MANDIRI = 'MANDIRI';
  static CIMB_NIAGA = 'CIMB_NIAGA';
  static ARTAJASA = 'ARTAJASA';
  static BNI_DISBURSEMENT = "BNI_DISBURSEMENT";

  static getStr(en) {
    switch (en) {
      case BankNameType.BCA:
        return 'BCA';
      case BankNameType.BNI:
        return 'BNI';
      case BankNameType.BRI:
        return 'BRI';
      case BankNameType.MANDIRI:
        return 'Mandiri';
      case BankNameType.CIMB_NIAGA:
        return 'CIMB Niaga';
      case BankNameType.ARTAJASA:
        return 'Artajasa';
      case BankNameType.BNI_DISBURSEMENT:
        return 'BNI Disbursement';
      default:
        return 'Unknown';
    }
  }
}

const BankNameTypeOpts = [
  { value: BankNameType.BCA, label: BankNameType.getStr(BankNameType.BCA) },
  { value: BankNameType.BNI, label: BankNameType.getStr(BankNameType.BNI) },
  { value: BankNameType.BRI, label: BankNameType.getStr(BankNameType.BRI) },
  { value: BankNameType.MANDIRI, label: BankNameType.getStr(BankNameType.MANDIRI) },
  { value: BankNameType.CIMB_NIAGA, label: BankNameType.getStr(BankNameType.CIMB_NIAGA) },
  { value: BankNameType.ARTAJASA, label: BankNameType.getStr(BankNameType.ARTAJASA) },
  { value: BankNameType.BNI_DISBURSEMENT, label: BankNameType.getStr(BankNameType.BNI_DISBURSEMENT) },
];

export { BankNameType, BankNameTypeOpts };
