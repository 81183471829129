import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes"
import {
  ROUTE_TRANSACTIONS_TOPUP,
  ROUTE_TRANSACTIONS_TRANSFER_DIPAY,
  ROUTE_TRANSACTIONS_TRANSFER_BANK,
  ROUTE_TRANSACTIONS_PPOB_LIST,
  ROUTE_TRANSACTIONS_PPOB_AUTODEBIT,
  ROUTE_TRANSACTIONS_PARTNER,
  ROUTE_TRANSACTIONS_CASHBACK,
  ROUTE_TRANSACTIONS_QRIS_MPM,
  // ROUTE_TRANSACTIONS_QRIS_CPM,
  // ROUTE_TRANSACTIONS_QRIS_TUNTAS,
  ROUTE_TRANSACTIONS_MDR,
  ROUTE_TRANSACTIONS_SETTLEMENT,
  ROUTE_TRANSACTIONS_REFUNDS,
  ROUTE_TRANSACTIONS_INJECTION_ONETOONE,
  ROUTE_TRANSACTIONS_INJECTION_BULK,
  ROUTE_TRANSACTIONS_DEDUCTION_ONETOONE,
  ROUTE_TRANSACTIONS_PARTNER_FEE,
  ROUTE_TRANSACTIONS_REWARD,
  ROUTE_TRANSACTIONS_TOPUP_TRANSFER_PARTER
} from "../utils/helpers/roleHelper"

import TopUp from '../pages/Transactions/TopUp'
import TransferEvy from '../pages/Transactions/Transfer/TransferEvy'
import TransferBank from '../pages/Transactions/Transfer/TransferBank'
import TransactionsPPOB from '../pages/Transactions/PPOB'
import AutoDebit from '../pages/Transactions/AutoDebit'
import TransactionsPartner from '../pages/Transactions/Partner'
import TransactionsPartnerFee from '../pages/Transactions/PartnerFee'
import TransactionsCashback from '../pages/Transactions/Cashback'
import TransactionsReward from '../pages/Transactions/Reward'
import TransactionQRISMPM from '../pages/Transactions/QRIS/MPM'
import TransactionQRISCPM from '../pages/Transactions/QRIS/CPM'
import TransactionQRISTuntas from '../pages/Transactions/QRIS/Tuntas'
import TransactionsMDR from '../pages/Transactions/MDR'
import TransactionsSettlement from '../pages/Transactions/Settlement'
import Refunds from '../pages/Transactions/Refunds'
import Injection from '../pages/Transactions/Injection'
import InjectionBulking from '../pages/Transactions/InjectionBulking'
import Deduction from '../pages/Transactions/Deduction'
import TopupTransferPartner from '../pages/Transactions/TopupTransferPartner'

import { EventType } from "../utils/constants/enums/eventTypes"

export const transactions = {
  type: NAVMENU,
  name: 'Transactions',
  icon: 'pe-7s-ribbon',
  children: [
    {
      type: NAVLINK,
      name: 'Top Up',
      icon: 'pe-7s-cloud-upload',
      url: '/transactions/top-up',
      routeTitle: ROUTE_TRANSACTIONS_TOPUP,
      component: TopUp
    },
    {
      type: NAVLINK,
      name: 'Top Up by Partner',
      url: '/transactions/topup-transfer-partner',
      routeTitle: ROUTE_TRANSACTIONS_TOPUP_TRANSFER_PARTER,
      component: TopupTransferPartner
    },
    {
      type: NAVLINK,
      name: 'Transfer Dipay',
      url: '/transactions/transfer-dipay',
      routeTitle: ROUTE_TRANSACTIONS_TRANSFER_DIPAY,
      component: TransferEvy
    },
    {
      type: NAVLINK,
      name: 'Transfer Bank',
      url: '/transactions/transfer-bank',
      routeTitle: ROUTE_TRANSACTIONS_TRANSFER_BANK,
      component: TransferBank
    },
    {
      type: NAVMENU,
      name: 'PPOB',
      children: [
        {
          type: NAVLINK,
          name: 'List',
          url: '/transactions/PPOB/list',
          routeTitle: ROUTE_TRANSACTIONS_PPOB_LIST,
          component: TransactionsPPOB
        },
        {
          type: NAVLINK,
          name: 'Auto Debit',
          icon: 'pe-7s-refresh-2',
          url: '/transactions/PPOB/auto-debit',
          routeTitle: ROUTE_TRANSACTIONS_PPOB_AUTODEBIT,
          component: AutoDebit
        },
      ]
    },
    {
      type: NAVLINK,
      name: 'Partner',
      url: '/transactions/partner',
      routeTitle: ROUTE_TRANSACTIONS_PARTNER,
      component: TransactionsPartner
    },
    {
      type: NAVLINK,
      name: 'Partner Fee',
      url: '/transactions/partner-fee',
      routeTitle: ROUTE_TRANSACTIONS_PARTNER_FEE,
      component: TransactionsPartnerFee
    },
    {
      type: NAVLINK,
      name: 'Cashback',
      url: '/transactions/cashback',
      routeTitle: ROUTE_TRANSACTIONS_CASHBACK,
      component: TransactionsCashback
    },
    {
      type: NAVLINK,
      name: 'Reward',
      url: '/transactions/reward',
      routeTitle: ROUTE_TRANSACTIONS_REWARD,
      component: TransactionsReward
    },
    {
      type: NAVMENU,
      name: 'QRIS',
      icon: 'pe-7s-next',
      children: [
        {
          type: NAVLINK,
          name: 'MPM',
          url: '/transactions/qris-mpm',
          routeTitle: ROUTE_TRANSACTIONS_QRIS_MPM,
          component: TransactionQRISMPM
        },
        // {
        //   type: NAVLINK,
        //   name: 'CPM',
        //   url: '/transactions/qris-cpm',
        //   routeTitle: ROUTE_TRANSACTIONS_QRIS_CPM,
        //   component: TransactionQRISCPM
        // },
        // {
        //   type: NAVLINK,
        //   name: 'Tuntas',
        //   url: '/transactions/qris-tuntas',
        //   routeTitle: ROUTE_TRANSACTIONS_QRIS_TUNTAS,
        //   component: TransactionQRISTuntas
        // },
      ]
    },
    {
      type: NAVLINK,
      name: 'MDR',
      url: '/transactions/mdr',
      routeTitle: ROUTE_TRANSACTIONS_MDR,
      component: TransactionsMDR
    },
    {
      type: NAVLINK,
      name: 'Merchant Settlement',
      icon: 'pe-7s-safe',
      url: '/transactions/settlement',
      routeTitle: ROUTE_TRANSACTIONS_SETTLEMENT,
      component: TransactionsSettlement
    },
    {
      type: NAVLINK,
      name: 'Refunds',
      url: '/transactions/refunds',
      routeTitle: ROUTE_TRANSACTIONS_REFUNDS,
      component: Refunds
    },
    {
      type: NAVMENU,
      name: 'Injection',
      icon: 'pe-7s-next',
      children: [
        {
          type: NAVLINK,
          name: 'One to One',
          url: '/transactions/injection/one-to-one',
          routeTitle: ROUTE_TRANSACTIONS_INJECTION_ONETOONE,
          eventType: EventType.NEW_INJECTION,
          component: Injection
        },
        {
          type: NAVLINK,
          name: 'Injection Bulking',
          icon: 'pe-7s-upload',
          url: '/transactions/injection/bulk',
          routeTitle: ROUTE_TRANSACTIONS_INJECTION_BULK,
          component: InjectionBulking
        },
      ]
    },
    {
      type: NAVMENU,
      name: 'Deduction',
      icon: 'pe-7s-prev',
      children: [
        {
          type: NAVLINK,
          name: 'One to One',
          icon: 'pe-7s-prev',
          url: '/transactions/deduction/one-to-one',
          routeTitle: ROUTE_TRANSACTIONS_DEDUCTION_ONETOONE,
          eventType: EventType.NEW_DEDUCTION,
          component: Deduction
        }
      ]
    },
  ]
}
