import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { businessesSettlementApi } from '../../../services/businessesSettlementApi';
import {
  BUSINESS_SETTLEMENT_MODULE,
  GET_DETAIL
} from '../../../utils/constants/actionTypes';
import AllStatus from '../../../utils/constants/enums/status';
import { toIDR } from '../../../utils/helpers/currency';
import useAsync from '../../../components/HooksUse/useAsync';
import StatusHistory from '../../../components/StatusHistory';
import ReactDOMServer from 'react-dom/server';
import { ExcelIcon } from '../../../components/Icons/Icons';
import { ExcelTable } from '../../../components/Table/ExcelTable';

const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))
const formatSheet = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const getClass = status => {
  let className = 'badge-primary';
  if (status === AllStatus.PENDING) {
    className = 'badge-warning'
  } else if (status === AllStatus.COMPLETED) {
    className = 'badge-success'
  } else if (status === AllStatus.REJECTED || status === AllStatus.FAILED) {
    className = 'badge-danger'
  }
  return className
}

const columnsSettlementList = [
  {
    title: "Date", name: 'createdAt', key: 'createdAt', thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell', render: (value) => (
      <div>{format(new Date(value), 'yyyy-MM-dd HH:mm:ss')}</div>
    )
  },
  { title: "Type", name: 'type', key: 'type', thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell' },
  { title: "Code", name: 'code', key: 'code', thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell' },
  {
    title: "Transaction Amount", name: 'amount', key: 'amount', render: (value, row) => (
      <span className={`font-weight-bold ${row.isDeduction ? "text-danger" : "text-success"}`}>
        {!row?.isDeduction ? toIDR(value): `(${toIDR(value)})`}
      </span>
    )
  },
  {
    title: "MDR", name: 'mdr', key: 'mdr', render: (value) =>
      <span className="font-weight-bold">
        {toIDR(value)}
      </span>
  },
  {
    title: "Recieved Amount", name: 'isDeduction', key: 'isDeduction', render: (value, row) =>
      <span className={`font-weight-bold ${value ? "text-danger" : "text-success"}`}>
        { !row?.isDeduction ? toIDR(row?.amount - row?.mdr) : toIDR(0)}
      </span>
  },
]

const excelColumnsSettlementList = [
  {
    title: "Date", name: 'createdAt', key: 'createdAt', thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell', render: (value) => (
      <div>{format(new Date(value), 'yyyy-MM-dd HH:mm:ss')}</div>
    )
  },
  { title: "Type", name: 'type', key: 'type', thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell' },
  { title: "Code", name: 'code', key: 'code', thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell' },
  {
    title: "Transaction Amount", name: 'amount', key: 'amount', render: (value, row) => (
      <span className={`font-weight-bold ${row.isDeduction ? "text-danger" : "text-success"}`}>
        { !row?.isDeduction ? toIDR(value, false) : `(${toIDR(value, false)})`}
      </span>
    )
  },
  {
    title: "MDR", name: 'mdr', key: 'mdr', render: (value) =>
      <span className="font-weight-bold">
        {toIDR(value, false)}
      </span>
  },
  {
    title: "Recieved Amount", name: 'isDeduction', key: 'isDeduction', render: (value, row) =>
      <span className={`font-weight-bold ${value ? "text-danger" : "text-success"}`}>
        {!row?.isDeduction ? toIDR(row?.amount - row?.mdr, false) : toIDR(0, false)}
      </span>
  },
]

const columnsApplicantInfo = [
  {
    title: "Merchant ID", name: 'applicant', key: 'applicant', render: v => (
      <span>{v?.uniqueId}</span>
    )
  },
  {
    title: "Owner", name: 'owner', key: 'applicant', render: v => (
      <span>{`${v?.firstName} ${v?.lastName}`}</span>
    )
  },
  {
    title: "Merchant Name", name: 'applicant', key: 'applicant', render: v => (
      <div>
        <span>{v?.businesses?.name}</span>
      </div>
    )
  },
  { title: "Address", name: 'address', key: 'address' },
]

const columnsSettlementInfo = [
  { title: "Trans. Code", name: 'code', key: 'code' },
  {
    title: "Created At", name: 'createdAt', key: 'createdAt', render: (value) => (
      <div>{format(new Date(value), 'dd MMMM yyyy HH:mm:ss')}</div>
    )
  },
  {
    title: "Current Status", name: 'status', key: 'status', render: (value) => (
      <span className={`badge ${getClass(value)}`}>{value}</span>
    )
  },
  {
    title: "Status Description", name: 'remarks', key: 'remarks', render: (value) => (
      <span>{value ? value : '-'}</span>
    )
  },
  {
    title: "Bank Biller", name: 'aggrBank', key: 'aggrBank', render: (value) => (
      <span>{value ? value : '-'}</span>
    )
  },
  {
    title: "Settlement Amount", name: 'total', key: 'total', render: (value) => (
      <>
        <span className="p">{toIDR(value)}</span>
      </>
    )
  },
  {
    title: "Fee", name: 'fee', key: 'fee', render: (value) => (
      <span className="p">{toIDR(value)}</span>
    )
  },
  {
    title: "MDR", name: 'mdr', key: 'mdr', render: (value) => (
      <span className="p">{toIDR(value)}</span>
    )
  },
  {
    title: "Transfer Amount", name: 'amount', key: 'amount', render: (value) => (
      <span className="h6 font-weight-bold text-primary">{toIDR(value)}</span>
    )
  }
]

function DetailTab({ index }) {
  const dispatch = useDispatch();

  const {
    value: { data: { transactions, merchantSettlement } = {} } = {},
  } = useAsync(
    useCallback(
      () => dispatch({
        module: BUSINESS_SETTLEMENT_MODULE,
        type: GET_DETAIL,
        call: businessesSettlementApi.detailSettlement,
        args: [index],
        tabKey: index
      }),
      [dispatch, index]
    ), true
  )

  return !transactions && !merchantSettlement ?
    null
    :
    transactions && merchantSettlement ? (
      <div className="row">
        <div className="col-lg-12 col-xl-8 mb-4">
          <div className="main-card card overflow-hidden">
            <RenderSettlementList data={transactions} />
          </div>
        </div>
        <div className="col-lg-12 col-xl-4 mb-4">
          <StatusHistory
            statusHistories={merchantSettlement.statusHistories}
            warningType={AllStatus.REQUEST}
            dangerType={AllStatus.REJECTED}
            className='mb-3'
          />
          <div className="main-card mt-4 card">
            {renderApplicantInfo(merchantSettlement)}
            <BankAccount bankAccount={merchantSettlement.bankAccount} />
            <hr className="my-0" />
            {renderSettlementInfo(merchantSettlement)}
          </div>
        </div>
      </div>
    ) : (
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
    )
}

const RenderSettlementList = ({ data }) => {
  const exportExcel = useCallback(async () => {
    const generatedTable = ExcelTable({ columns: excelColumnsSettlementList, items: data })
    const html = ReactDOMServer.renderToStaticMarkup(generatedTable);
    const excelHref = 'data:application/vnd.ms-excel;base64,'
    const filename = `Business_Settlement_List_${format(
      new Date(),
      "yyyy-MM-dd"
    )}.xls`

    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{html}</body></html>';

    const context = {
      worksheet: 'Worksheet',
      html,
    };

    let a = document.createElement('a')
    a.href = excelHref + base64(formatSheet(template, context));
    a.download = filename
    if (a.download.split(".").filter(el => el.includes("xls")).length > 1) {
      a.download = a.download.replace(".xls", "")
    }
    a.click()
  }, [data]);

  return (
    <div>
      <div className="card-body">
        <h5 className="card-title mb-3">Transaction List</h5>
        <div className="table-control-top-right mb-3">
          <button className="btn btn-excel btn-icon btn-icon-only" onClick={exportExcel}>
            <span className='mr-2'>Export Excel</span>
            <ExcelIcon size={20} />
          </button>
        </div>
      </div>
      <div className="form-group">
        <table>
          <thead className='border-bottom'>
            <tr>
              {
                columnsSettlementList.map(column => (
                  <th key={column.key} className={`col-2 py-2 ${column?.thClass}`} style={{ color: '#B5B5C3', fontSize: '12px', textTransform: 'uppercase' }}>
                    {column.title}
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              data?.map((data, index) => (
                <tr key={data.code} className='border-top border-light' style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : ''}` }}>
                  {
                    columnsSettlementList.map(column => (
                      <td key={column.key} className={`col-2 py-2 ${column.tdClass}`}>
                        {
                          column?.render
                            ? column.render(data[column.key], data)
                            : data[column.key]
                        }
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const renderApplicantInfo = (data) => (
  <div className="card-body border-bottom">
    <h5 className="card-title mb-3">Merchant Info</h5>
    <div className="form-group">
      {
        columnsApplicantInfo.map(column => (
          <div key={column.title} className="row">
            <div className="col-4 pr-1">
              <div className="d-flex justify-content-between">
                <label className="text-grey mb-1">{column.title}</label>
                <span>:</span>
              </div>
            </div>
            <div className="col-8 pl-0">
              <span className="mb-0 font-weight-semibold">
                {
                  column?.render
                    ? column.render(data[column.key], data)
                    : data.applicant[column.key]
                }
              </span>
            </div>
          </div>
        ))
      }
    </div>
  </div>
)

const renderSettlementInfo = (data) => (
  <div className="card-body">
    <h5 className="card-title mb-3">Settlement Info</h5>
    {
      columnsSettlementInfo.map(column => (
        <div key={column.key} className="row mb-2 align-items-center">
          <div className="col-4">
            <label className="text-grey mb-0">{column.title}</label>
          </div>
          <div className="col-8 text-right">
            <span className="mb-0 font-weight-semibold">
              {
                column?.render
                  ? column.render(data[column.key])
                  : data[column.key]
              }
            </span>
          </div>
        </div>
      ))
    }
  </div>
)

const BankAccount = ({ bankAccount }) => (
  <div className="card-body">
    <h5 className="card-title mb-3">Bank Account Destination</h5>
    <div className="bank-accounts-wrapper">
      <div className="bank-accounts-list">
        <div className="bank-account-data pr-0">
          <div className="bank-account-logo">
            <img src={bankAccount?.bank?.logo?.url ?? ''} alt={bankAccount?.bank?.abbr} />
          </div>
          <div>
            <p className="bank-account-bank">
              {bankAccount?.bank?.name ?? 'Bank Not Found'}
            </p>
            <p className="bank-account-name">
              {bankAccount?.accountName}
            </p>
            <p className="bank-account-number">
              {bankAccount?.accountNumber}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default DetailTab
