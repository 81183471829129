import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const transactionsPartnerFeeApi = {
  list: ({ page, limit, sort, partner, startDate, endDate }) => {
    let url = `/transactions/partner-fee-list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (partner) {
      url += `&senderId=${partner}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  }
}
