import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BUSINESS_ADMIN_PTEN_VERIFY_MODULE, BUSINESS_ADMIN_VERIFY_MODULE, BUSINESS_MODULE, CLOSE_TAB, GET_DETAIL, REQUEST_UPDATE_BUSINESS_MODULE, UPDATE } from '../../../../utils/constants/actionTypes';
import { Tab, Tabs, TabHeader } from '../../../../components/TabsLandscape/TabsLandscape';
import OperationHourForm from './OperationHours';
import { toast } from 'react-toastify';
// import GeneralInformation from './MerchantRegistration/GeneralInformation';
import ArchiveTab from './Archive';
import SocialMedia from './SocialMedia';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../services/businessesApi';
import BlackListCard from './BlackListCard'
import SuspendCard from './SuspendCard'
import UnlockCard from './UnlockCard';
// import WalletCard from './WalletCard';
import MerchantOwner from './MerchantRegistration/MerchantOwner';
import MerchantOutlet from './MerchantRegistration/MerchantOutlet';
import MerchantBankAccount from './MerchantRegistration/MerchantBankAccount';
import PTENInformation from './PTENInformation';
import HistoryChanges from '../../../../components/templates/HistoryDialog/HistoryChanges';
import StatusHistory from '../../../../components/templates/HistoryDialog/StatusHistory';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import RejectDialog from '../../../../components/Dialog/RejectDialog';
import objectPath from 'object-path';
// import PopUp from '../../../../components/PopUp';
import BusinessInformation from './MerchantRegistration/GeneralInformation/index';
import NotesCard from './NotesCard';
// import MerchantSettlementType from './SettlementType';
import Statistics from './Statistics';
import ClosedAccountCard from './ClosedAccountCard';
import MerchantInfo from './MerchantInfo';
import VerifyIdHistoryCard from '../../../../components/templates/VerifyIdHistoryCard';
import ReferalCode from './ReferalCode';
import useAsync from '../../../../components/HooksUse/useAsync';
import BusinessStatusHistories from '../../../../components/templates/HistoryDialog/BusinessStatusHistories';
import DeclineDialog from '../../../../components/Dialog/DeclineDialog';
import { useHistory } from 'react-router-dom';
import { requestUpdateBusinessesApi } from '../../../../services/requestUpdateBusinessesApi';
import { RequestUpdateBusinessType } from '../../../../utils/constants/enums/requestUpdateBusinessTypes';
import { format } from 'date-fns';
import AllStatus from '../../../../utils/constants/enums/status';
// import OutletList from './OutletList';
// import EmployeeList from './EmployeeList';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import { rejectReasonApi } from '../../../../services/rejectReasonApi';
import { rejectReasonTypes } from '../../../../utils/constants/enums/rejectReasonTypes';
import { combineBy } from '../../../../utils/helpers/combineBy';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

const initialCheckbox = {
  profileSet: false,
  bankAccountSet: false,
  businessSet: false,
  outletSet: false,
}

const DetailTab = ({ index, module, reducerPath }) => {
  const [item, setItem] = useState(null);
  const [connectedPartner, setConnectedPartner] = useState(null);
  const [requestUpdate, setRequestUpdate] = useState(null);
  const [merchantInfo, setMerchantInfo] = useState(null);
  const [confirmMsg, setConfirmMsg] = useState(null);
  const [requester, setRequester] = useState(null);
  const [activeKey, setActiveKey] = useState(1);
  const [showChanges, setShowChanges] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showStatusHistories, setShowStatusHistories] = useState(false);
  const [showStatusHistoriesNew, setShowStatusHistoriesNew] = useState(false)
  const [statusHistories, setStatusHistories] = useState(false);
  const dispatch = useDispatch();
  const [reasons, setReasons] = useState(null);
  const { key, loading, informationUncompleted } = useSelector(state => objectPath.get(state, reducerPath)).tabs[index];
  const { admin } = useSelector(state => ({ admin: state.common.currentAdmin }));
  const isMounted = useMountedState();
  const [isRejecting, setIsRejecting] = useState(false);
  const history = useHistory();

  const columnsCheckbox = useMemo(() => [
    {
      key: 'profileSet',
      label: <span className="font-weight-bold">Identitas Pemilik</span>,
      checked: informationUncompleted?.profileSet,
      defaultChecked: informationUncompleted?.profileSet,
      id: 'profile',
      information: [
        'Nama Pemilik',
        'Foto KTP Pemilik',
        'Nomor KTP',
        'Tempat Lahir',
        'Tanggal Lahir',
        'Alamat Lahir',
        'Foto Selfie Dengan KTP',
      ]
    },
    {
      key: 'bankAccountSet',
      label: <span className="font-weight-bold">Rekening Bank</span>,
      checked: informationUncompleted?.bankAccountSet,
      defaultChecked: informationUncompleted?.bankAccountSet,
      id: 'bank',
      information: [
        'Nama Bank',
        'Nomor Rekening',
      ]
    },
    {
      key: 'businessSet',
      label: <span className="font-weight-bold">Informasi Usaha</span>,
      checked: informationUncompleted?.businessSet,
      defaultChecked: informationUncompleted?.businessSet,
      id: 'business',
      information: [
        'Nama Usaha',
        'Bidang Usaha',
      ]
    },
    {
      key: 'outletSet',
      label: <span className="font-weight-bold">Informasi Outlet</span>,
      checked: informationUncompleted?.outletSet,
      defaultChecked: informationUncompleted?.outletSet,
      id: 'outlet',
      information: [
        'Nomor Telepon Outlet',
        'Set Lokasi',
        'Alamat Lengkap Outlet',
        'Patokan Lokasi (Opsional)',
        'Foto Outlet Dari Luar'
      ]
    },
  ], [
    informationUncompleted?.bankAccountSet,
    informationUncompleted?.businessSet,
    informationUncompleted?.outletSet,
    informationUncompleted?.profileSet
  ])

  const getDetail = useCallback(
    () => {
      if (module === REQUEST_UPDATE_BUSINESS_MODULE) {
        dispatch({
          module: REQUEST_UPDATE_BUSINESS_MODULE,
          type: GET_DETAIL,
          call: requestUpdateBusinessesApi.detail,
          args: [key],
          tabKey: index
        }).then(({ data: {
          requestUpdate,
          businessInfo,
          merchantInfo,
          requester,
          confirmMsg,
          connectedPartner
        } }) => {
          if (isMounted()) setItem(businessInfo);
          if (isMounted()) setRequestUpdate(requestUpdate);
          if (isMounted()) setMerchantInfo(merchantInfo);
          if (isMounted()) setRequester(requester);
          if (isMounted()) setConfirmMsg(confirmMsg);
          if (isMounted()) setConnectedPartner(connectedPartner);
        }).catch(() => { })
      } else {
        dispatch({
          module: module,
          type: GET_DETAIL,
          call: businessesApi.detail,
          args: [key],
          tabKey: index
        }).then(({ data: { business, connectedPartner } }) => {
          if (isMounted()) setItem(business);
          if (isMounted()) setConnectedPartner(connectedPartner);
        }).catch(() => { })
      }
    },
    [dispatch, index, key, isMounted, module]
  )

  const onSuccess = useCallback(() => {
    toast('Business Updated', { type: "success" });
    getDetail()
  }, [getDetail])

  const {
    generalInformation,
    merchantOwner,
    merchantBankAccount,
    merchantOutlet,
    readyToVerify,
    ptenInformation
  } = useMemo(() => {
    const res = {
      generalInformation: false,
      merchantOwner: false,
      merchantBankAccount: false,
      merchantOutlet: false,
      readyToVerify: false,
      ptenInformation: false
    }

    if (item) {
      const {
        name,
        type,
        MCC,
        MCriteria,
        email,
        phoneNumber,
        address,
        province,
        city,
        postalCode,
        location,
        nmid,
        imageQris,
        merchant: {
          defaultBankAccount,
          firstName,
          dateOfBirth,
          placeOfBirth,
          archive: {
            identityCard: {
              identificationNumber,
              cardPhoto,
              selfPhoto
            }
          }
        }

      } = item;

      if (name && type && MCC && MCriteria) res.generalInformation = true;
      if (firstName && dateOfBirth && placeOfBirth && identificationNumber && cardPhoto && selfPhoto && address) res.merchantOwner = true;
      if (defaultBankAccount) res.merchantBankAccount = true;
      if (nmid && imageQris) res.ptenInformation = true;
      if (email && phoneNumber && address && province && city && postalCode && location) res.merchantOutlet = true;
      if (res.generalInformation && res.merchantOwner && res.merchantBankAccount && res.merchantOutlet) res.readyToVerify = true;
    }

    return res
  }, [item])

  const onRejectPten = useCallback((e, remark) => {
    setIsRejecting(true);
    dispatch({
      module: BUSINESS_ADMIN_PTEN_VERIFY_MODULE,
      type: UPDATE,
      call: businessesApi.rejectPtenVerify,
      args: [item._id, remark],
      tabKey: index
    })
      .finally(() => {
        if (isMounted()) {
          history.push('/businesses/verify')
          setIsRejecting(false);
          onSuccess();
          dispatch({ module, type: CLOSE_TAB, key })
        }
      });
  }, [dispatch, index, isMounted, item, onSuccess, history, module, key])

  const onVerify = useCallback(() => {
    setIsUpdating(true);
    dispatch({ module: BUSINESS_ADMIN_VERIFY_MODULE, type: UPDATE, call: businessesApi.requestVerify, args: [item._id], tabKey: index })
      .finally(() => {
        if (isMounted()) {
          history.push('/businesses/admin-approval')
          setIsUpdating(false);
          onSuccess();
          dispatch({ module, type: CLOSE_TAB, key })
        }
      });
  }, [dispatch, index, isMounted, item, onSuccess, history, module, key])

  const onReject = useCallback((e, remark, checkboxData) => {
    setIsRejecting(true);
    dispatch({
      module: BUSINESS_ADMIN_VERIFY_MODULE,
      type: UPDATE,
      call: businessesApi.reject,
      args: [item._id, remark, checkboxData],
      tabKey: index
    })
      .finally(() => {
        if (isMounted()) {
          history.push('/businesses/verify')
          setIsRejecting(false);
          onSuccess();
          dispatch({ module, type: CLOSE_TAB, key })
        }
      });
  }, [dispatch, index, isMounted, item, onSuccess, history, module, key])

  const onVerifyPten = useCallback(() => {
    setIsUpdating(true);
    dispatch({ module: BUSINESS_ADMIN_PTEN_VERIFY_MODULE, type: UPDATE, call: businessesApi.verifyPten, args: [item._id], tabKey: index })
      .finally(() => {
        if (isMounted()) {
          history.push('/businesses/list')
          setIsUpdating(false);
          onSuccess();
          dispatch({ module, type: CLOSE_TAB, key })

        }
      });
  }, [dispatch, index, isMounted, item, onSuccess, history, module, key])

  // Status Histories
  // const {
  //   execute: getStatusHistories,
  //   value: { data } = {}
  // } = useAsync(businessesApi.statusHistories, key);

  const getStatusHistories = useCallback(() => {
    if (item) {
      businessesApi.statusHistories(item?._id)
        .then(({ data: { statusHistories } }) => {
          setStatusHistories(statusHistories)
        })
    }
  }, [item])

  useEffect(() => {
    getStatusHistories()
  }, [getStatusHistories])

  const approveHistory = requestUpdate ? requestUpdate.statusHistories.find(history => history.status === 'APPROVED') : null;
  const declineHistory = requestUpdate ? requestUpdate.statusHistories.find(history => history.status === 'REJECTED') : null;

  const getClass = (status) => {
    let className = 'badge-primary';
    if (status === 'PROCESSING') {
      className = 'badge-warning'
    } else if (status === 'APPROVED') {
      className = 'badge-success'
    } else if (status === 'REJECTED') {
      className = 'badge-danger'
    }
    return className
  }

  const callType = (type) => {
    switch (type) {
      case 'approve':
        return requestUpdateBusinessesApi.approve
      case 'decline':
        return requestUpdateBusinessesApi.reject
      case 'settlement':
        return requestUpdateBusinessesApi.updateSettlementStatus
      default:
        return null;
    }
  }

  const approveDeclineAsync = useAsync((type, id, remarks) => {
    return dispatch({
      type: null,
      call: callType(type),
      args: [id, remarks]
    });
  });

  const handleAction = useCallback(actionKey => (id, remarks) => {
    setIsUpdating(true);
    approveDeclineAsync.execute(actionKey, requestUpdate?._id, remarks)
      .then(() => {
        getDetail();
        dispatch({ module: REQUEST_UPDATE_BUSINESS_MODULE, type: CLOSE_TAB, key })
        if (actionKey === 'decline') {
          history.push('/businesses/admin-approval')
        }
        if (actionKey === 'approve') {
          history.push('/businesses/pten/verify')
        }
      })
      .catch(() => { })
      .finally(() => setIsUpdating(false))
  }, [
    approveDeclineAsync, dispatch, getDetail, history, key, requestUpdate
  ])

  const generateDocument = useCallback(() => {
    loadFile(
      '/FORMULIR_PERMINTAAN-PENGADUAN.docx',
      function (error, content) {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.setData({
          fullname: merchantInfo?.name,
          businessName: item?.name,
          merchantId: item?.nmid,
          phoneNumber: merchantInfo.phoneNumber,
          email: merchantInfo.email,
          reason: merchantInfo.reason,
          requestType: requestUpdate.updateType,
          nik: merchantInfo.identityCard.identificationNumber,
          requestDate: format(new Date(requestUpdate.createdAt), 'dd MMMM yyyy'),
          approvedDate: format(new Date(approveHistory.createdAt), 'dd MMMM yyyy')
        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join('\n');
            console.log('errorMessages', errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        var out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        }); //Output the document using Data-URI
        saveAs(out, 'FORMULIR_PERMINTAAN-PENGADUAN.docx');
      }
    );
  }, [
    approveHistory, item, merchantInfo, requestUpdate
  ]);

  const getReasons = useCallback(() => {
    dispatch({
      module: BUSINESS_ADMIN_VERIFY_MODULE,
      type: null,
      call: businessesApi.rejectReasons,
      args: [],
      tabKey: index
    }).then(({ data: { reasons } }) => {
      setReasons(reasons)
    })
  }, [dispatch, index])

  const {
    value: { data: { reasons: approvalReasons = [] } = {} } = {},
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: rejectReasonApi.list,
      args: [{
        page: 0,
        limit: 0,
        category: rejectReasonTypes.CLOSE_ACCOUNT
      }]
    }),
    [dispatch]), true)

  useEffect(() => {
    if (readyToVerify && !item.status.checked) {
      dispatch({ type: null, call: businessesApi.check, args: [item._id] })
    }
  }, [readyToVerify, item, dispatch])

  useEffect(() => {
    if (module === BUSINESS_ADMIN_VERIFY_MODULE) {
      getReasons();
    }
    getDetail();
  }, [getDetail, getReasons, module]);

  const header = useMemo(() => {
    if (module === BUSINESS_MODULE) {
      return (
        <div>
          <div className="row">
            <UnlockCard
              admin={admin}
              item={item}
              onSuccess={onSuccess}
            />
            <SuspendCard
              admin={admin}
              item={item}
              onSuccess={() => {
                getStatusHistories(key)
                onSuccess()
              }}
            />
            <BlackListCard
              admin={admin}
              item={item}
              onSuccess={onSuccess}
            />
            <ClosedAccountCard
              admin={admin}
              item={item}
              onSuccess={onSuccess}
            />
          </div>
          <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowChanges(true)}>
            Show Changes History
          </button>
          <HistoryChanges
            serviceApi={businessesApi.updateHistoryList}
            id={item?._id}
            show={showChanges}
            setShow={setShowChanges}
            historyKey='businessHistories'
          />
          <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistoriesNew(true)}>
            Show Status History (New!)
          </button>
          <BusinessStatusHistories statusHistories={statusHistories?.history} show={showStatusHistoriesNew} setShow={setShowStatusHistoriesNew} />

          {item?.statusHistories?.length ?
            <>
              <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistories(true)}>
                Show Status Histories (Old)
              </button>
              <StatusHistory
                show={showStatusHistories}
                setShow={setShowStatusHistories}
                className="mb-3"
                statusHistories={item?.statusHistories}
              />
            </>
            : null
          }
        </div>
      )
    }
    if (module === BUSINESS_ADMIN_VERIFY_MODULE) {
      return (
        <div>
          <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowChanges(true)}>
            Show Changes History
          </button>
          <HistoryChanges
            serviceApi={businessesApi.updateHistoryList}
            id={item?._id}
            show={showChanges}
            setShow={setShowChanges}
            historyKey='businessHistories'
          />
          <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistoriesNew(true)}>
            Show Status History (New!)
          </button>
          <BusinessStatusHistories statusHistories={statusHistories?.history} show={showStatusHistoriesNew} setShow={setShowStatusHistoriesNew} />

          {item?.statusHistories?.length ?
            <>
              <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistories(true)}>
                Show Status Histories (Old)
              </button>
              <StatusHistory
                show={showStatusHistories}
                setShow={setShowStatusHistories}
                className="mb-3"
                statusHistories={item?.statusHistories}
              />
            </>
            : null
          }
          {item?.status?.rejecterVerifyPten &&
            <div className="alert custom-alert alert-warning">
              <div>
                <p className="mb-0"><b>Reject Reason: </b>{item?.status?.rejectVerifyPtenReason}</p>
              </div>
            </div>
          }
          <div className="alert custom-alert alert-primary">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="mb-0">Mohon untuk melengkapi data yang ada di sesi <b>INFORMASI BISNIS</b> untuk melanjutkan Verifikasi.</p>
              </div>
              <div className="d-flex">
                <RejectDialog
                  checkbox={columnsCheckbox}
                  checkboxLabel="Uncompleted registration data"
                  options={reasons}
                  initialCheckbox={initialCheckbox}
                  className="btn btn-danger mr-2"
                  label="Reject"
                  fullWidth={true}
                  maxWidth="sm"
                  desc="Please fill reject reason clearly"
                  onSubmit={onReject}
                />
                <AlertDialog
                  fullWidth={false}
                  title="Apakah anda yakin ingin mem-verifikasi akun ini?"
                  onAgree={onVerify}
                  buttonComponent={
                    <LoadingBtn
                      loading={isUpdating}
                      className={`btn btn-lg ${readyToVerify ? "btn-primary" : "btn-secondary"}`}
                      disabled={!readyToVerify}
                    >
                      Verify
                    </LoadingBtn>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (module === REQUEST_UPDATE_BUSINESS_MODULE) {
      return requestUpdate ? (
        <div>
          <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowChanges(true)}>
            Show Changes History
          </button>
          <HistoryChanges
            serviceApi={businessesApi.updateHistoryList}
            id={item?._id}
            show={showChanges}
            setShow={setShowChanges}
            historyKey='businessHistories'
          />
          <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistoriesNew(true)}>
            Show Status History (New!)
          </button>
          <BusinessStatusHistories statusHistories={statusHistories?.history} show={showStatusHistoriesNew} setShow={setShowStatusHistoriesNew} />

          {item?.statusHistories?.length ?
            <>
              <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistories(true)}>
                Show Status Histories (Old)
              </button>
              <StatusHistory
                show={showStatusHistories}
                setShow={setShowStatusHistories}
                className="mb-3"
                statusHistories={item?.statusHistories}
              />
            </>
            : null
          }
          <div className={`alert custom-alert ${requestUpdate?.status === AllStatus.REJECTED ? 'alert-danger' : 'alert-primary'}`}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className='d-flex align-items-center gap-8 mb-2'>
                  <h6 className='font-weight-bold m-0'>{RequestUpdateBusinessType.getStr(requestUpdate?.updateType)}</h6>
                  <span className={`badge ${getClass(requestUpdate.status)}`}>{requestUpdate.status}</span>
                </div>
                <p className="mb-2">Requester: {combineBy([requester.firstName, requester.lastName])} ({requester.email})</p>
                <p className="mb-2">Request Date: {format(new Date(requestUpdate?.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
              </div>
              <div>
                {
                  requestUpdate.status === 'PROCESSING' ?
                    <div className="d-flex justify-content-center">
                      <DeclineDialog
                        isLoading={isUpdating}
                        desc={confirmMsg?.reject}
                        fullWidth={true}
                        maxWidth="sm"
                        label="Reject"
                        className="btn btn-outline-danger mr-2"
                        reasons={approvalReasons}
                        dataId={requestUpdate._id}
                        onSubmit={handleAction('decline')}
                      />
                      {
                        item.buttonEnableSettle
                        &&
                        <AlertDialog
                          fullWidth={false}
                          title="Apakah anda yakin?"
                          onAgree={handleAction('settlement')}
                          buttonComponent={
                            <LoadingBtn
                              type="button"
                              className="btn btn-warning mr-2"
                            >
                              Settlement
                            </LoadingBtn>
                          }
                        />
                      }
                      {
                        !item.buttonEnableSettle
                        &&
                        <AlertDialog
                          fullWidth={false}
                          title={confirmMsg?.approve}
                          onAgree={handleAction('approve')}
                          notes={
                            requestUpdate.updateType === RequestUpdateBusinessType.CLOSED ?
                              <>
                                <div className="font-weight-bold mb-2">Sebelum melakukan approve perhatikan hal berikut :</div>
                                <ul className="pl-4 mb-0">
                                  <li>Cek saldo apakah sudah menjadi 0</li>
                                  <li>Cek apakah ini benar Mitra INDOFUND, apakah ada tagihan</li>
                                  <li>Cek settlement apakah sudah berhasil dilakukan</li>
                                </ul>
                              </>
                              :
                              null
                          }
                          buttonComponent={
                            <LoadingBtn
                              type="button"
                              className="btn btn-primary"
                            >
                              Approve
                            </LoadingBtn>
                          }
                        />
                      }
                    </div>
                    :
                    <>
                      {
                        requestUpdate.status === 'APPROVED' &&
                        <div>
                          <label className="text-grey mb-1">Approval Date</label>
                          <p className="font-weight-semibold mb-1">{format(new Date(approveHistory.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                          <button
                            className="btn btn-primary w-100"
                            onClick={generateDocument}
                          >
                            Download Formulir
                          </button>
                        </div>
                      }
                      {
                        requestUpdate.status === 'REJECTED' &&
                        <>
                          <div className='mb-2'>
                            <label className="text-grey m-0">Disapproval Date</label>
                            <p className="mb-0 font-weight-semibold">{format(new Date(declineHistory.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                          </div>
                          <div>
                            <label className="text-grey m-0">Reject Reason</label>
                            <p className="mb-0 font-weight-semibold">{requestUpdate.rejectReason || '-'}</p>
                          </div>
                        </>
                      }
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      ) : null
    }
    if (module === BUSINESS_ADMIN_PTEN_VERIFY_MODULE) {
      return (
        <div className="alert custom-alert alert-primary">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="mb-0">Mohon untuk melengkapi data yang ada di sesi <b>INFORMASI PTEN</b> untuk melanjutkan Verifikasi.</p>
            </div>
            <div className="d-flex">
              <DeclineDialog className="btn btn-danger mr-2" isLoading={isRejecting} onSubmit={onRejectPten} />
              <AlertDialog
                fullWidth={false}
                title="Apakah anda yakin ingin meng-approve akun ini?"
                onAgree={onVerifyPten}
                buttonComponent={
                  <LoadingBtn
                    loading={isUpdating}
                    className={`btn btn-lg ${ptenInformation ? "btn-primary" : "btn-secondary"}`}
                    disabled={!ptenInformation}
                  >
                    Verify
                  </LoadingBtn>
                }
              />
            </div>
          </div>
        </div>
      )
    }


    return null
  }, [
    item,
    admin,
    requestUpdate,
    requester,
    columnsCheckbox,
    isUpdating,
    module,
    onVerifyPten,
    onReject,
    onRejectPten,
    onSuccess,
    onVerify,
    ptenInformation,
    readyToVerify,
    showChanges,
    showStatusHistories,
    showStatusHistoriesNew,
    statusHistories,
    getStatusHistories,
    key,
    approveHistory,
    confirmMsg,
    declineHistory,
    generateDocument,
    handleAction,
    approvalReasons,
    isRejecting,
    reasons
  ])

  return loading && !item ?
    null
    :
    item ?
      <div>
        {header}
        <div className="mb-3">
          <Tabs
            activeKey={activeKey}
            onClick={(i) => setActiveKey(i)}
          >
            {/* Informasi Bisnis */}
            <TabHeader title="Informasi Bisnis" />
            <Tab
              title="Identitas Pemilik"
              icon={merchantOwner ? <CheckCircleIcon className="text-success" style={{ fontSize: '1rem' }} /> : null}
            >
              <MerchantOwner
                item={item}
                module={module}
                onSuccess={onSuccess}
                readOnly={true || item?.status?.approved}
              />
              {item?.verifyIdHistory?.length ?
                <VerifyIdHistoryCard item={item?.verifyIdHistory} />
                :
                null
              }
            </Tab>
            <Tab
              title="Rekening Bank"
              icon={merchantBankAccount ? <CheckCircleIcon className="text-success" style={{ fontSize: '1rem' }} /> : null}
            >
              <MerchantBankAccount
                merchant={item.merchant}
                onSuccess={onSuccess}
                readOnly={Boolean(module === BUSINESS_ADMIN_VERIFY_MODULE)}
              />
            </Tab>
            <Tab
              title="Informasi Usaha"
              icon={generalInformation ? <CheckCircleIcon className="text-success" style={{ fontSize: '1rem' }} /> : null}
            >
              <BusinessInformation
                item={item}
                module={module}
                onSuccess={onSuccess}
                readOnly={true}
              />
            </Tab>
            <Tab
              title="Informasi Outlet"
              icon={merchantOutlet ? <CheckCircleIcon className="text-success" style={{ fontSize: '1rem' }} /> : null}
            >
              <MerchantOutlet
                item={item}
                module={module}
                onSuccess={onSuccess}
                readOnly={true}
              />
            </Tab>
            {/* Informasi Bisnis

            {/* Informasi PTEN */}
            <TabHeader title="Informasi PTEN" />
            <Tab
              title="Informasi PTEN"
              icon={ptenInformation ? <CheckCircleIcon className="text-success" style={{ fontSize: '1rem' }} /> : null}
            >
              <PTENInformation
                item={item}
                module={module}
                onSuccess={onSuccess}
                readOnly={Boolean(
                  module === BUSINESS_MODULE ||
                  module === BUSINESS_ADMIN_PTEN_VERIFY_MODULE ||
                  module === REQUEST_UPDATE_BUSINESS_MODULE
                )}
              />
            </Tab>
            {/* Informasi PTEN */}

            {/* Lainnnya */}
            <TabHeader title="Lainnnya" />
            <Tab title="Informasi Akun">
              <MerchantInfo
                connectedPartner={connectedPartner}
                merchant={item.merchant}
                item={item}
                onSuccess={onSuccess}
              />
            </Tab>
            {/* <Tab title="Daftar Outlet">
              <OutletList
                id={item?.merchant?._id}
              />
            </Tab>
            <Tab title="Daftar Pegawai">
              <EmployeeList
                id={item?.merchant?._id}
              />
            </Tab> */}
            <Tab title="Statistik">
              <Statistics
                id={item?.merchant?._id}
                module={BUSINESS_MODULE}
              />
            </Tab>
            <Tab title="Notes">
              <NotesCard
                merchant={item.merchant}
                item={item}
                onSuccess={onSuccess}
                readOnly={true}
                module={module}
              />
            </Tab>
            <Tab title="Referal Code">
              <ReferalCode
                merchant={item.merchant}
                item={item}
                onSuccess={onSuccess}
                readOnly={true}
                module={module}
              />
            </Tab>
            <Tab title="Operation Hours">
              <OperationHourForm id={item._id} operationHours={item?.operationHours ?? []} onSuccess={onSuccess} />
            </Tab>
            <Tab title="Archives (SIUP)">
              <ArchiveTab business={item} onSuccess={onSuccess} />
            </Tab>
            <Tab title="Social Media">
              <SocialMedia business={item} onSuccess={onSuccess} />
            </Tab>
            {/* Lainnnya */}
          </Tabs>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
