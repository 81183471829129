import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KYC_UPGRADES_MODULE, UPDATE, GET_DETAIL } from '../../../../../utils/constants/actionTypes';
import * as yup from 'yup';
import { numberRegExp } from '../../../../../utils/constants/regexTypes';
import IdentityImage from '../../../../../components/IdentityImage';
import DeclineDialog from '../../../../../components/Dialog/DeclineDialog';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import { VERIFY_ID } from '../../../../../redux/reducers/kyc/upgrades';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from "react-hook-form";
import HookTextField from '../../../../../components/Forms/HookTextField';
import FormField from '../../../../../components/Forms/FormField';
import EvyDatePicker from '../../../../../components/Forms/EvyDatePicker';
import CitySelect from '../../../../../components/Select/CitySelect';
import useAsync from '../../../../../components/HooksUse/useAsync';
import EvySelectField from '../../../../../components/Forms/EvySelectField';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import { userApi } from '../../../../../services/userApi';
import { dataListApi } from '../../../../../services/dataListApi';
import { rejectReasonApi } from '../../../../../services/rejectReasonApi';
import { rejectReasonTypes } from '../../../../../utils/constants/enums/rejectReasonTypes';
import PopupCropperKYC from '../../../../../components/PopUp/PopUpCropper';
import getCroppedImg, { urlToFile } from '../../../../../utils/helpers/cropImage';
import { format } from 'date-fns';
import { combineBy } from '../../../../../utils/helpers/combineBy';
import VerifyIdHistoryCard from '../../../../../components/templates/VerifyIdHistoryCard';

import styles from './detail.module.scss'
import { partnerTypes } from '../../../../../utils/constants/enums/partnerTypes';

const getAlertVarian = string => {
  let className = '';
  if (string === "match") {
    className = "";
  } else if (string === "partial") {
    className = "text-warning";
  } else if (string === "nomatch") {
    className = "text-danger";
  }
  return className;
}

const convertArrayToObject = (array, key, value) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    };
  }, initialValue);
};

const schema = yup.object().shape({
  identificationNumber: yup.string().matches(numberRegExp, 'Only Number Allowed').required(),
  firstName: yup.string()
    .required('First Name is required')
    .test(
      "firstNameFormat",
      "First Name can't contain 2 words or whitespace",
      value => value.indexOf(' ') === -1
    ),
  lastName: yup.string(),
  dateOfBirth: yup.string().typeError('Field required').required(),
  placeOfBirth: yup.string(),
  job: yup.object().typeError('Job is required').required('Job is required'),
  religion: yup.string(),
  city: yup.string().nullable(),
  address: yup.string(),

  currentAddress: yup.string(),
  nationality: yup.string(),
  gender: yup.object().typeError('Gender is required').required('Gender is required'),
  upgradeNote: yup.string(),
});

const Detail = ({ index }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.kyc.upgrades.tabs[index]);
  const [matchStatus, setMatchStatus] = useState();

  const {
    execute: getDetail,
    value: { data: { user: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: KYC_UPGRADES_MODULE,
      type: GET_DETAIL,
      call: userApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true);

  const onSuccess = () => {
    getDetail();
  }

  const generateAlert = useMemo(
    () => {
      let html = <div></div>;

      if (typeof matchStatus === 'string') {
        html = (
          <div className="alert alert-danger m-0">
            <b>{matchStatus}</b>
          </div>
        )
      } else {
        html = (
          <div className="alert alert-success m-0">
            <span className="mr-2">NIK: <b className={getAlertVarian(matchStatus?.nik)}>{matchStatus?.nik}</b>,</span>
            <span className="mr-2">Nama: <b className={getAlertVarian(matchStatus?.name)}>{matchStatus?.name}</b>,</span>
            <span className="mr-2">Tempat Lahir: <b className={getAlertVarian(matchStatus?.birth_place)}>{matchStatus?.birth_place}</b>,</span>
            <span className="mr-2">Tanggal Lahir: <b className={getAlertVarian(matchStatus?.birth_date)}>{matchStatus?.birth_date}</b>,</span>
            <span className="mr-2">Tingkat Kesamaan: <b className={getAlertVarian(matchStatus?.similarity)}>{matchStatus?.similarity}</b>,</span>
          </div>
        );
      }

      return html
    }, [matchStatus])

  return loading && !item ?
    <div className="main-card mb-3 card">
      <div className="card-body">Loading...</div>
    </div>
    :
    <>
      {matchStatus &&
        <div className="card mb-3">
          <div className="card-body">
            {generateAlert}
          </div>
        </div>
      }

      {item ?
        <div className="main-card mb-3 card">
          <div className="card-body">
            <MyForm
              index={index}
              item={item}
              onSuccess={onSuccess}
              setMatchStatus={setMatchStatus}
            />
          </div>
        </div>
        :
        <div className="card">
          <div className="card-body">
            <h5 className="text-center mb-0">Not Found</h5>
          </div>
        </div>
      }

      {item?.verifyIdHistory?.length ?
        <div className="card">
          <div className="card-body">
            <VerifyIdHistoryCard item={item?.verifyIdHistory} />
          </div>
        </div>
        : null
      }
    </>
}

const MyForm = ({ item, index, onSuccess, setMatchStatus }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [errorMinimumPixels, setErrorMinimumPixels] = useState('');

  // Cropper
  const [showCropImage, setShowCropImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedPreview, setCroppedPreview] = useState(null);
  const [imageKtp, setImageKtp] = useState(null);
  const token = window.localStorage.getItem('token');

  const selfieImage = item?.archive?.identityCard?.selfPhoto?.url + '?token=' + token;
  const aspect = 3 / 4;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true)
    try {
      const croppedImage = await getCroppedImg(
        selfieImage,
        croppedAreaPixels,
        rotation
      )
      if (croppedImage.error) {
        setErrorMinimumPixels(croppedImage.error)
      } else {
        setCroppedImage(croppedImage.file)
        setCroppedPreview(croppedImage.img)
        setErrorMinimumPixels('')
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, selfieImage, rotation])

  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      identificationNumber: item.archive.identityCard.identificationNumber ?? '',
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
      dateOfBirth: item.dateOfBirth ? item.dateOfBirth : new Date("1995-01-01"),
      placeOfBirth: item.placeOfBirth ?? '',
      job: item.job ? { label: item.job, value: item.job } : null,
      religion: item.religion ?? '',
      city: item.city ?? null,
      address: item.address ?? '',

      nationality: item.nationality ?? '',
      gender: item.gender ? { label: item.gender, value: item.gender } : null,
      currentAddress: item.addressCurrent ?? '',
      upgradeNote: item.upgradeNote ?? '',
    },
  });
  const {
    firstName,
    lastName,
    placeOfBirth,
    identificationNumber,
    dateOfBirth,
    job,
    city,
    gender
  } = watch([
    'firstName',
    'lastName',
    'placeOfBirth',
    'identificationNumber',
    'dateOfBirth',
    'job',
    'city',
    'gender'
  ]);

  const {
    pending,
    value: { data: jobs = [] } = {},
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: dataListApi.getJobs }),
    [dispatch]), true)

  const {
    value: { data: { reasons = [] } = {} } = {},
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: rejectReasonApi.list,
      args: [{
        page: 0,
        limit: 0,
        category: rejectReasonTypes.KYC
      }]
    }),
    [dispatch]), true)

  const onSubmit = (values) => {
    const data = {
      ...values,
      job: values.job?.value ?? "",
      gender: values.gender?.value ?? "",
      addressCurrent: values.currentAddress
    };
    setSubmitting(true);
    dispatch({ module: KYC_UPGRADES_MODULE, type: UPDATE, call: userApi.approve, args: [item._id, data], tabKey: index })
      .catch(() => { })
      .finally(() => { if (isMounted()) setSubmitting(false) });
  };

  const fetchData = useCallback(async () => {
    if (item?.archive?.identityCard?.cardPhoto?.url) {
      const ktpImage = await urlToFile(item?.archive?.identityCard?.cardPhoto?.url + '?token=' + token, "ktpImage.jpg")

      if (ktpImage) {
        setImageKtp(ktpImage)
      }
    }
  }, [item?.archive?.identityCard?.cardPhoto?.url, token])

  useEffect(() => {
    fetchData()

    return () => { };
  }, [fetchData, item])

  const verify = async () => {
    await showCroppedImage()
    if (croppedImage && imageKtp) {
      const data = {
        nik: identificationNumber,
        name: combineBy([firstName, lastName]),
        birth_place: placeOfBirth,
        birth_date: format(new Date(dateOfBirth), 'dd-MM-yyyy'),
        image: croppedImage,
        imageKtp,
        userId: item._id,
      };

      setMatchStatus(null);
      return dispatch({ module: KYC_UPGRADES_MODULE, type: VERIFY_ID, call: userApi.verifyID, args: [data] })
        .then(res => {
          if (isMounted()) {
            setMatchStatus(convertArrayToObject(res.data, "fieldName", "status"));
          }
        })
        .catch(err => {
          if (isMounted()) setMatchStatus(err.message);
        })
        .finally(() => {
          onSuccess()
        })
    }
  }

  const verifyAsync = useAsync(verify);

  const onVerify = () => {
    verifyAsync.execute()
    setShowCropImage(false)
    setCroppedImage(null)
  }

  const onReject = (dataId, remarks) => {
    setRejecting(true);
    dispatch({
      module: KYC_UPGRADES_MODULE,
      type: UPDATE, call:
        userApi.reject,
      args: [dataId, remarks],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setRejecting(false) });
  }

  useEffect(() => {
    register("dateOfBirth");
    register("job");
    register("city");
    register("gender");

    return () => {
      unregister("dateOfBirth")
      unregister("job")
      unregister("city");
      unregister("gender");
    };
  }, [register, unregister]);

  const GetInfo = () => {
    if (errorMinimumPixels) {
      return <p className="text-danger">Pixels harus lebih dari 480 x 640 pixels, mohon untuk zoom out</p>
    } else {
      return <p>Pastikan <b>wajah pengguna</b> memenuhi gambar.</p>
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-4">
        <div className="col-12 col-md-6 ">
          <IdentityImage label="Card Photo" image={item?.archive?.identityCard?.cardPhoto?.url} />
          <p>Photo size: {Math.floor(item?.archive?.identityCard?.cardPhoto?.size / 1000)} KB ({item?.archive?.identityCard?.cardPhoto?.size} bytes)</p>
        </div>
        <div className="col-12 col-md-6">
          <IdentityImage label="Self Photo" image={item?.archive?.identityCard?.selfPhoto?.url} />
          <p>Photo size: {Math.floor(item?.archive?.identityCard?.selfPhoto?.size / 1000)} KB ({item?.archive?.identityCard?.selfPhoto?.size} bytes)</p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Biodata</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Identification Number"
            placeholder="Enter Identification Number"
            name="identificationNumber"
            error={errors.identificationNumber}
            helperText={errors.identificationNumber ? errors.identificationNumber.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="Nationality"
            placeholder="Enter Nationality"
            name="nationality"
            error={errors.nationality}
            helperText={errors.nationality ? errors.nationality.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="First Name"
            placeholder="Enter First Name"
            name="firstName"
            error={errors.firstName}
            helperText={errors.firstName ? errors.firstName.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="Last Name"
            placeholder="Enter Last Name"
            name="lastName"
            error={errors.lastName}
            helperText={errors.lastName ? errors.lastName.message : null}
            row
          />
          <EvySelectField
            name="gender"
            label="Gender"
            placeholder="Enter Gender"
            options={[{ label: 'MALE', value: "MALE" }, { label: 'FEMALE', value: "FEMALE" }]}
            onChange={(v) => setValue('gender', v, { shouldValidate: true })}
            value={gender}
            error={errors.gender}
            helperText={errors.gender ? errors.gender.message : null}
            advanceSelect
            isLoading={pending}
            row
          />
          <FormField
            row
            label="Date of Birth"
            error={errors.dateOfBirth}
            helperText={errors.dateOfBirth ? errors.dateOfBirth.message : null}
          >
            <EvyDatePicker
              value={dateOfBirth}
              variant="inline"
              autoOk
              format="dd-MM-yyyy"
              onChange={(v) => setValue('dateOfBirth', v, { shouldValidate: true })}
            />
          </FormField>
          <HookTextField
            ref={register}
            label="Place of Birth"
            name="placeOfBirth"
            error={errors.placeOfBirth}
            helperText={errors.placeOfBirth ? errors.placeOfBirth.message : null}
            row
          />
          <EvySelectField
            name="job"
            label="Job"
            placeholder="Enter Job"
            options={[...jobs.map(job => ({ value: job.occupation_id, label: job.occupation_id }))]}
            onChange={(v) => setValue('job', v, { shouldValidate: true })}
            value={job}
            error={errors.job}
            helperText={errors.job ? errors.job.message : null}
            advanceSelect
            row
          />
          <HookTextField
            ref={register}
            label="Religion"
            placeholder="Enter Religion"
            name="religion"
            error={errors.religion}
            helperText={errors.religion ? errors.religion.message : null}
            row
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Address</h5>
        </div>
        <div className="col-12 col-md-9">
          <CitySelect
            row
            label="City"
            placeholder="Enter City"
            onChange={v => setValue('city', v?.value ?? null, { shouldValidate: true })}
            value={city}
            error={errors.city}
            helperText={errors.city ? errors.city.message : null}
          />
          <HookTextField
            ref={register}
            label="Address"
            placeholder="Enter Address"
            name="address"
            error={errors.address}
            helperText={errors.address ? errors.address.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="Current Address"
            placeholder="Enter Current Address"
            name="currentAddress"
            error={errors.currentAddress}
            helperText={errors.currentAddress ? errors.currentAddress.message : null}
            row
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Partner</h5>
        </div>
        {item.connectedPartner.length ?
          <div className="col-12 col-md-9">
            <div className='row'>
              <div className='col-form-label col-sm-3 text-md-right'>
                <FormField
                  label="Connected Partner"
                >
                </FormField>
              </div>
              <div className='col-sm-9 d-grid gap-8'>
                {
                  item?.connectedPartner.map(v => {
                    return (
                      <div className={styles.connectedPartner}>
                        <div className="d-flex align-items-center">
                          <h6 className="mb-0 mr-1">{partnerTypes.getStr(v?.companyCode)}</h6>
                          {v?.privateUser ?
                            <div className={styles.badge}>
                              <span>Private</span>
                            </div>
                            : null
                          }
                        </div>
                        {item?.companyName ?
                          <p className='mt-1'>{item?.companyName}</p>
                          :
                          null
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          : null
        }
      </div>
      <div className="row">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Partner</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            disabled
            label="Connected Partner"
            name="connectedPartner"
            value={item.connectedPartner.length ? item?.connectedPartner.map(v => " " + v?.companyCode) : "-"}
            row
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Additional</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Upgrade Note (Optional)"
            placeholder="Enter Note"
            name="upgradeNote"
            error={errors.upgradeNote}
            helperText={errors.upgradeNote ? errors.upgradeNote.message : null}
            row
          />
          <FormField row>
            {item?.buttonVerifyEnabled &&
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => setShowCropImage(true)}
              >
                Verify ID
              </button>
            }
            <LoadingBtn
              loading={submitting}
              type="submit"
              className="btn btn-primary mr-2"
            >
              Update
            </LoadingBtn>
            <DeclineDialog
              isLoading={rejecting}
              className="btn btn-outline-danger mr-2 mr-sm-0 ml-sm-2"
              reasons={reasons}
              dataId={item._id}
              onSubmit={onReject}
            />
          </FormField>
        </div>
      </div>
      <div className='row'>
        <div className="col-12 col-md-5"></div>
        <div className="col-12 col-md-7">
          {item?.usedNikBy?.length ?
            <div className="p-3 border d-grid gap-8">
              {item?.usedNikBy?.map((v, i) => {
                return (
                  <div className="alert alert-warning font-weight-bold mb-0" key={i}>
                    {v}
                  </div>
                )
              })}
            </div>
            :
            null
          }
        </div>
      </div>
      <PopupCropperKYC
        showCropImage={showCropImage}
        setShowCropImage={setShowCropImage}
        croppedImage={croppedImage}
        croppedPreview={croppedPreview}
        cropperProps={{
          image: selfieImage,
          crop,
          setCrop,
          zoom,
          setZoom,
          rotation,
          setRotation,
          onCropComplete,
          aspect
        }}
        isLoading={isLoading}
        onVerify={onVerify}
        setCroppedImage={setCroppedImage}
        showCroppedImage={showCroppedImage}
        title="Potong Swafoto"
        info={<GetInfo />}
      />
    </form >
  )
}

export default Detail;
