import React, { useMemo } from 'react';
import Resource from '../../../../components/Resource';
import { endOfDay, format, startOfDay } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import { useSelector } from 'react-redux';
import { userApi } from '../../../../services/userApi';
import DetailTab from './DetailTab';
import { KYC_HISTORIES_MODULE } from '../../../../utils/constants/actionTypes';
import AllStatus from '../../../../utils/constants/enums/status';
import { AdminRole } from '../../../../utils/constants/enums/adminRoles';
import { ADMIN_SELECT, DATE_PICKER, SELECT_FIELD } from '../../../../utils/constants/inputTypes';
import KYCHistoryStatusOpts from '../../../../utils/constants/enums/status/KYCHistoryStatusOpts';

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.kyc.histories);

  const columns = [
    {
      title: 'Processed At', key: 'processedAt', name: 'processedAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd HH:mm:ss');
      }
    },
    {
      title: 'User', key: 'firstName', name: 'user_name', render: function (v, row) {
        return combineBy([row.firstName, row.lastName]);
      }
    },
    { title: 'Job', key: 'job', name: 'job' },
    { title: 'Phone Number', key: 'phoneNumber', name: 'email' },
    { title: 'Status', key: 'archive.upgradeHistories.status', name: 'status', sortable: false, render: v => AllStatus.getStr(v) },
    {
      title: 'Checker', key: 'checker', name: 'checker', render: (v, row) => {
        const checker = row.archive.upgradeHistories?.checker ?? null;
        return checker ? `${combineBy([checker.firstName, checker.lastName])} (${AdminRole.getStr(checker.role)})` : '-'
      }
    }
  ]

  const apiQuery = useMemo(() => {
    const search = filter.checker ? `checker|${filter.checker?.value}` : null;
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      status: filter.status,
      startDate,
      endDate,
      search
    }
  }, [filter, currentPage, limit, sort])

  return (
    <Resource
      title="KYC Histories"
      subTitle="KYC History List."
      icon="pe-7s-wristwatch"
      list={{
        columns: columns,
        reducerPath: "kyc.histories",
        module: KYC_HISTORIES_MODULE,
        call: userApi.upgradeHistoryList,
        apiQuery: apiQuery,
        filters: [
          {
            label: "Filter Status",
            type: SELECT_FIELD,
            key: "status",
            options: KYCHistoryStatusOpts,
            value: filter.status
          },
          {
            label: "Start Date",
            type: DATE_PICKER,
            key: "startDate",
            value: filter.startDate,
            placeholder: "Select date"
          },
          {
            label: "End Date",
            type: DATE_PICKER,
            key: "endDate",
            value: filter.endDate,
            placeholder: "Select date"
          },
          {
            label: "Select Checker",
            type: ADMIN_SELECT,
            key: "checker",
            value: filter.checker
          },
        ]
      }}
      detail={{
        component: DetailTab,
        additionalKey: {
          key: "userId",
          value: "archive.upgradeHistories._id"
        }
      }}
    />
  );
}

export default Index;
