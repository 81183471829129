import React, { useState } from 'react';
import PopUp from './PopUp';

export default function IdentityImage(props) {
  const { label, image, variant } = props;

  const token = window.localStorage.getItem('token');

  const [show, setShow] = useState(false)

  return (
    <>
      <div onClick={() => setShow(true)} className={`img-thumb-bordered ${variant ? variant : ''}`}>
        <label>{label}</label>
        <img src={image + '?token=' + token} alt="" />
      </div>
      <Zoom show={show} setShow={setShow} src={image + '?token=' + token} />
    </>
  )
}

function Zoom({ show, setShow, src }) {
  const [isMirror, setIsMirror] = useState(false)

  return (
    <PopUp
      in={show}
      onClose={() => {
        setShow(false)
        setIsMirror(false)
      }}
      width={800}
    >
      <div className="main-card card">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-center">
            <img src={src} alt="" style={{ maxHeight: '600px', height: '100%', maxWidth: '100%', transform: isMirror ? 'scaleX(-1)' : '' }} />
          </div>
          <hr />
          <button
            onClick={() => {
              setIsMirror(!isMirror)
            }}
            className="d-block btn btn-primary w-100 mb-2"
          >
            {isMirror ? "Kembali" : "Cerminkan Foto"}
          </button>
          <button
            onClick={() => {
              setShow(false)
            }}
            className="d-block btn btn-outline-primary w-100"
          >
            Close
          </button>
        </div>
      </div>
    </PopUp>
  )
}