import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { INJECTION_BULKING_MODULE } from "../../../utils/constants/actionTypes";
import DetailTab from "./DetailTab";
import InjectTab from "./InjectTab";
import { injectionBulkingApi } from "../../../services/injectionBulkingApi";

const Index = () => {
  const { currentPage, sort, limit } = useSelector(state => state.injectionBulking);

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Description', key: 'description', name: 'description', render: v => v ? v : '-' },
    {
      title: 'Status', key: 'status', name: 'status'
    }
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort
    };
  }, [currentPage, limit, sort]);

  return (
    <div>
      <Resource
        title="Injection Bulking"
        subTitle="Full Injection Bulking Control includes Create & Approval."
        icon="pe-7s-next"
        list={{
          columns: columns,
          reducerPath: "injectionBulking",
          call: injectionBulkingApi.list,
          apiQuery: apiQuery,
          module: INJECTION_BULKING_MODULE,
        }}
        additionalTab={{
          component: InjectTab,
          title: "Inject"
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
